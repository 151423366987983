import { AuthError } from '../exceptions/authError'
import { ThrottleError } from '../exceptions/throttleError'
import { AuthHelper } from './authHelper'

export class ProtoHelper {
  private constructor() {}

  public static setOptions(options: { responseType: string; headers: {} }) {
    return {
      ...options,
      headers: { ...options.headers, 'Content-Type': 'application/x-protobuf' },
      responseType: 'arraybuffer',
    }
  }

  public static requestBefore(data: any, type: any): ArrayBuffer {
    const err = type.verify(data)
    if (err) {
      throw Error(err)
    }

    return new Uint8Array(type.encode(data).finish()).buffer
  }

  public static async requestAfter<TResult>(response: Response, responseProtoType: any = null): Promise<TResult> {
    if (response.status === 429) {
      throw new ThrottleError()
    }

    if (AuthHelper.isAuthFailed(response)) {
      throw new AuthError()
    }

    if (response.status !== 200) {
      throw Error(`Произошла ошибка: ${response.status} ${response.statusText}`)
    }

    if (!responseProtoType) {
      return
    }

    const arraybuffer = await response.arrayBuffer()

    return responseProtoType.decode(new Uint8Array(arraybuffer))
  }
}
