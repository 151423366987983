import { combineReducers } from 'redux'
import listPharmaciesNetworksReducer from './listPharmaciesNetworksReducer'
import editPharmaciesNetworkReducer from './editPharmaciesNetworkReducer'
import pharmaciesNetworksSyncReducer from './pharmaciesNetworksSyncReducer'

export const rootPharmaciesNetworkReducer = combineReducers({
  list: listPharmaciesNetworksReducer,
  edit: editPharmaciesNetworkReducer,
  sync: pharmaciesNetworksSyncReducer,
})
