import { Dispatch } from 'redux'

import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { AutocompleteSearchResults, EAddressPart, IAutocompleteSearchResults } from '../../../proto/models'
import { ISelectState } from '../../types/types'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'

const addressesUrl = 'dictionaries/pharmacies'

export interface ISettlementEditState {
  settlements: ISelectState[]
}

const initialState: ISettlementEditState = {
  settlements: [],
}

const SETTLEMENTS_LOADED = 'SETTLEMENTS_LOADED'
export const settlementsLoaded = (settlements: IAutocompleteSearchResults) => ({
  type: SETTLEMENTS_LOADED,
  payload: settlements.SearchResults.map((item) => ({ label: item.name, value: item.id })),
})

export const fetchSettlements = (country: ISelectState, region: ISelectState) => async (dispatch: Dispatch) => {
  const settlements = await ProtoClient.get<IAutocompleteSearchResults>(
    addressesUrl + '/search',
    AutocompleteSearchResults,
    {
      AddressPart: EAddressPart.Settlement,
      CountryId: GuidHelper.toString(country.value),
      RegionId: GuidHelper.toString(region.value),
    }
  )

  return dispatch(settlementsLoaded(settlements))
}

export const ON_SETTLEMENT_CHANGE = 'ON_SETTLEMENT_CHANGE'
export const settlementChange = () => async (dispatch: Dispatch) => {
  return dispatch({ type: ON_SETTLEMENT_CHANGE })
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SETTLEMENTS_LOADED:
      return {
        ...state,
        loadingSettlements: false,
        settlements: action.payload,
      }
    default:
      return state
  }
}
