import { ProtoClient } from "@external/rp.ui/utils/protoClient"
import { ISearchDto, PtgSearchResultDto, InnPartSearchResultDto, MeasureSearchResultDto, IAtcGroupDto, IPtgDto, IDrugFormFullDto, IInnPartDto, IMeasureDto, AtcGroupSearchResultDto, DrugFormFullSearchResultDto } from "src/proto/models"

export class DrugSearchService {
  private static url = 'dictionaries/search-drugs'

  static async onAtcGroupChange(query: string): Promise<IAtcGroupDto[]> {
    return await DrugSearchService.fetch(DrugSearchService.url + '/atc-group', query, AtcGroupSearchResultDto)
  }

  static async onPtgChange(query: string): Promise<IPtgDto[]> {
    return await DrugSearchService.fetch(DrugSearchService.url + '/ptg', query, PtgSearchResultDto)
  }

  static async onDrugFormFullChange(query: string): Promise<IDrugFormFullDto[]> {
    return await DrugSearchService.fetch(DrugSearchService.url + '/drug-form-full', query, DrugFormFullSearchResultDto)
  }

  static async onInnPartChange(query: string): Promise<IInnPartDto[]> {
    return await DrugSearchService.fetch(DrugSearchService.url + '/inn-part', query, InnPartSearchResultDto)
  }

  static async onMeasureChange(query: string): Promise<IMeasureDto[]> {
    return await DrugSearchService.fetch(DrugSearchService.url + '/measure', query, MeasureSearchResultDto)
  }

  static async fetch(url: string, query: string, SearchResult: any): Promise<any> {
    if (!query) {
      return []
    }

    const res = await ProtoClient.get<ISearchDto>(url, SearchResult, { query: query })

    return res?.items ?? []
  }
}
