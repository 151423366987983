import { WebClient } from '@external/rp.ui/utils/protoClient'
import { AppDispatch, IAppState } from '../rootReducer'
import { EWorkStatus, IWorkInfo } from '../../proto/models'
import { checkStatus, CheckStatusDispose } from '@external/rp.ui/utils/workStatusChecker'
import { createReducer } from '@reduxjs/toolkit'

export interface IDrugsImportState {
  workStatus: IWorkInfo
  timer: CheckStatusDispose
}

const initialState: IDrugsImportState = {
  workStatus: null,
  timer: null,
}

const drugsUrl = 'dictionaries/drugs'

export const importDrugs = () => async (dispatch: AppDispatch, getState: () => IAppState) => {
  await WebClient.request(drugsUrl + '/import', 'POST')

  var status: IWorkInfo = {
    status: EWorkStatus.Queue,
    progress: 0,
  }
  dispatch({
    type: CHECK_IMPORT_STATUS,
    payload: { status: status },
  })

  dispatch(checkImportStatus())
}

export const CHECK_IMPORT_STATUS = 'DRUGS_CHECK_IMPORT_STATUS'
const SET_TIMER = 'DRUGS_SET_TIMER'
export const checkImportStatus = () => (dispatch: AppDispatch, getState: () => IAppState) => {
  const dispose = checkStatus(drugsUrl + '/import-progress', (value) =>
    dispatch({
      type: CHECK_IMPORT_STATUS,
      payload: { status: value },
    })
  )

  dispatch(clearImportTimer())

  dispatch({
    type: SET_TIMER,
    payload: { timer: dispose },
  })
}

export const clearImportTimer = () => (dispatch: AppDispatch, getState: () => IAppState) => {
  const timer = getState().drugs.import.timer
  if (typeof timer === 'function') {
    timer()
  }
}

const drugsImportReducer = createReducer(initialState, {
  [CHECK_IMPORT_STATUS]: (state, action) => {
    state.workStatus = action.payload.status
  },
  [SET_TIMER]: (state, action) => {
    state.timer = action.payload.timer
  },
})

export default drugsImportReducer
