import { Dispatch } from 'redux'

import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { AutocompleteSearchResults, EAddressPart, IAutocompleteSearchResults } from '../../../proto/models'
import { ISelectState } from '../../types/types'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'

const addressesUrl = 'dictionaries/pharmacies'

export interface IHousesEditState {
  houses: ISelectState[]
}

const initialState: IHousesEditState = {
  houses: [],
}

const HOUSES_LOADED = 'HOUSES_LOADED'
export const housesLoaded = (houses: IAutocompleteSearchResults) => ({
  type: HOUSES_LOADED,
  payload: houses.SearchResults.map((item) => ({ label: item.name, value: item.id })),
})

export const fetchHouses =
  (country: ISelectState, region: ISelectState, settlement?: ISelectState, street?: ISelectState) =>
  async (dispatch: Dispatch) => {
    const houses = await ProtoClient.get<IAutocompleteSearchResults>(
      addressesUrl + '/search',
      AutocompleteSearchResults,
      {
        AddressPart: EAddressPart.House,
        CountryId: GuidHelper.toString(country.value),
        RegionId: GuidHelper.toString(region.value),
        SettlementId: settlement && settlement.value ? GuidHelper.toString(settlement.value) : null,
        StreetId: street && street.value ? GuidHelper.toString(street.value) : null,
      }
    )

    return dispatch(housesLoaded(houses))
  }

export default (state = initialState, action: any) => {
  switch (action.type) {
    case HOUSES_LOADED:
      return { ...state, loadingHouses: false, houses: action.payload }
    default:
      return state
  }
}
