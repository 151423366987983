import React from 'react'
import App from '../components/App/App'
import { Provider } from 'react-redux'
import { rootReducer } from '../reducers/rootReducer'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

const middleware = getDefaultMiddleware({ immutableCheck: false, serializableCheck: false, thunk: true })

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV?.toLowerCase() === 'development',
})

export default () => (
  <Provider store={store}>
    <App />
  </Provider>
)
