import { combineReducers } from 'redux'

import { rootPharmacyEditFormReducer } from './editForm'
import editPharmacyReducer from './editPharmacyReducer'
import listPharmacyReducer from './listPharmacyReducer'
import pharmacySyncReducer from './pharmacySyncReducer'

export const rootPharmacyReducer = combineReducers({
  list: listPharmacyReducer,
  edit: editPharmacyReducer,
  editForm: rootPharmacyEditFormReducer,
  sync: pharmacySyncReducer,
})
