import { Action, combineReducers } from 'redux'
import { FormReducerMapObject, FormStateMap, reducer as formReducer } from 'redux-form'
import { ThunkDispatch } from 'redux-thunk'

import { IEditState, IListState } from '../data/state'
import { IPharmaciesNetwork, IPharmacy } from '../proto/models'
import addressesReducer, { IAddressesState } from './addressesReducer'
import { ICategoryState, rootCategoryReducer } from './categories'
import { rootCountryCallingCodeReducer } from './countryCallingCode'
import { ICountryCallingCodeSyncState } from './countryCallingCode/countryCallingCodeReducer'
import { rootCurrencyReducer } from './currency'
import { ICurrencySyncState } from './currency/CurrencySyncReducer'
import { rootDrugsReducer } from './drugs'
import { DrugEditFormState } from './drugs/drugReducer'
import { IDrugsImportState } from './drugs/drugsImportReducer'
import { IDrugsSyncState } from './drugs/drugsSyncReducer'
import { rootLanguageReducer } from './languages'
import { ILanguageSyncState } from './languages/LanguageSyncReducer'
import nosologyReducer, { NosologyEditFormState } from './nosologies/nosologyEditFormreducer'
import { rootPharmaciesNetworkReducer } from './pharmaciesNetworks'
import { IPharmaciesNetworksSyncState } from './pharmaciesNetworks/pharmaciesNetworksSyncReducer'
import { rootPharmacyReducer } from './pharmacy'
import { ICountriesEditState } from './pharmacy/editForm/countryReducer'
import { IDistrictEditState } from './pharmacy/editForm/districtReducer'
import { IHousesEditState } from './pharmacy/editForm/houseReducer'
import { IPharmaciesNetworkEditState } from './pharmacy/editForm/pharmaciesNetworkReducer'
import { IRegionsEditState } from './pharmacy/editForm/regionReducer'
import { ISettlementEditState } from './pharmacy/editForm/settlementReducer'
import { IStreetsEditState } from './pharmacy/editForm/streetReducer'
import { IPharmacySyncState } from './pharmacy/pharmacySyncReducer'

export interface IPharmacyEditForm {
  pharmaciesNetwork: IPharmaciesNetworkEditState
  countries: ICountriesEditState
  regions: IRegionsEditState
  settlements: ISettlementEditState
  districts: IDistrictEditState
  streets: IStreetsEditState
  houses: IHousesEditState
}

export interface IPharmaciesState {
  list: IListState<IPharmacy>
  edit: IEditState<IPharmacy>
  editForm: IPharmacyEditForm
  sync: IPharmacySyncState
}

export interface IDrugsState {
  import: IDrugsImportState
  sync: IDrugsSyncState
  edit: DrugEditFormState
}

export interface ICurrenciesState {
  sync: ICurrencySyncState
}

export interface ILanguagesState {
  sync: ILanguageSyncState
}

export interface IPharmaciesNetworksState {
  list: IListState<IPharmaciesNetwork>
  edit: IEditState<IPharmaciesNetwork>
  sync: IPharmaciesNetworksSyncState
}

export interface ICountryCallingCodeReducerState {
  sync: ICountryCallingCodeSyncState
}

export interface IAppState {
  dispatch: AppDispatch
  drugs: IDrugsState
  addresses: IAddressesState
  pharmacies: IPharmaciesState
  pharmaciesNetworks: IPharmaciesNetworksState
  currencies: ICurrenciesState
  languages: ILanguagesState
  countryCallingCodes: ICountryCallingCodeReducerState
  categories: ICategoryState
  form: FormStateMap
  nosologies: NosologyEditFormState
}

export const rootReducer = combineReducers({
  form: formReducer.plugin({
    pharmacyEditForm: (state) => state,
  }),
  drugs: rootDrugsReducer,
  addresses: addressesReducer,
  pharmacies: rootPharmacyReducer,
  pharmaciesNetworks: rootPharmaciesNetworkReducer,
  currencies: rootCurrencyReducer,
  countryCallingCodes: rootCountryCallingCodeReducer,
  languages: rootLanguageReducer,
  categories: rootCategoryReducer,
  nosologies: nosologyReducer,
})

export type AppDispatch = ThunkDispatch<IAppState, unknown, Action>
