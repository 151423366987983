import React from 'react'
import { IWorkInfo, EWorkStatus } from '../../proto/models'
import { LinearProgress } from '@material-ui/core'
import styles from './AsyncWork.module.scss'

export interface IAsyncWorkContext {
  workStatus: IWorkInfo
  startWorkElement: JSX.Element
  doneWorkName?: JSX.Element
}

const AsyncWork = (props: IAsyncWorkContext) => {
  if (!props.workStatus) {
    return <div className={styles.buttonWrapper}>{props.startWorkElement}</div>
  }

  const lineProgress = <LinearProgress variant="determinate" value={props.workStatus.progress} />

  switch (props.workStatus.status) {
    case EWorkStatus.Done: {
      if (props.doneWorkName) {
        return (
          <>
            <div className={styles.buttonWrapper}>{props.startWorkElement}</div>
            <div className={styles.buttonWrapper}>{props.doneWorkName}</div>
          </>
        )
      } else {
        return <div className={styles.buttonWrapper}>{props.startWorkElement}</div>
      }
    }
    case EWorkStatus.Error:
    case EWorkStatus.NotRunned:
      return <div className={styles.buttonWrapper}>{props.startWorkElement}</div>
    case EWorkStatus.Queue:
    case EWorkStatus.InProgress: {
      return (
        <>
          <div className={styles.buttonWrapper}>{props.startWorkElement}</div>
          <div className={styles.progressWrapper}>{lineProgress}</div>
        </>
      )
    }
    default: {
      return <div className={styles.buttonWrapper}>{props.startWorkElement}</div>
    }
  }
}

export default AsyncWork
