import { withPrefix } from 'gatsby'
import MaterialTable, { Column, MTableToolbar } from 'material-table'
import React, { Component, SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import AsyncWork, { IAsyncWorkContext } from '@external/rp.ui/components/AsyncWork/AsyncWork'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'
import { Button, TablePagination } from '@material-ui/core'
import { navigate } from '@reach/router'

import { IPharmaciesNetwork, IPharmaciesNetworks, IWorkInfo } from '../../../../proto/models'
import {
  deletePharmaciesNetworks,
  fetchPharmaciesNetworks,
  onChangePageIndex,
  onChangePageSize,
} from '../../../../reducers/pharmaciesNetworks/listPharmaciesNetworksReducer'
import { syncPharmaciesNetworks } from '../../../../reducers/pharmaciesNetworks/pharmaciesNetworksSyncReducer'
import { IAppState } from '../../../../reducers/rootReducer'
import DeleteButton from '../../../buttons/DeleteButton'
import EditButton from '../../../buttons/EditButton'
import { tableIcons } from '../../../shared/tableIcons'
import styles from './PharmaciesNetworksDictionary.module.scss'

interface IPharmaciesNetworksesContext {
  dispatch: Dispatch
  loading: boolean
  pharmaciesNetworks: IPharmaciesNetwork[]
  rowCount: number
  paging: {
    pageIndex: number
    pageSize: number
  }
  syncStatus: IWorkInfo
}

class PharmaciesNetworksDictionary extends Component<IPharmaciesNetworksesContext> {
  constructor(props: IPharmaciesNetworksesContext) {
    super(props)
    this.loadData()
  }
  private pharmaciesNetworksUrl = '/dictionaries/pharmaciesNetworks'
  private editWindowPath = '/pharmaciesNetwork/edit'

  private columns: Column<IPharmaciesNetworks>[] = [
    {
      title: '',
      field: 'edit',
      width: '100px',
      render: (rowData: { id: Uint8Array }) => <EditButton onClick={(_) => this.onEditButtonClick(rowData.id)} />,
    },
    { title: 'Наименование', field: 'name' },
    {
      title: '',
      field: 'delete',
      render: (rowData: { id: Uint8Array }) => <DeleteButton onClick={(_) => this.onDeleteButtonClick(rowData.id)} />,
    },
  ]

  private onEditButtonClick = (id: Uint8Array) => {
    const path = `${this.editWindowPath}/${GuidHelper.toString(id)}`
    navigate(withPrefix(path))
  }

  private onDeleteButtonClick = (id: Uint8Array) => {
    this.props.dispatch(deletePharmaciesNetworks(GuidHelper.toString(id)))
  }

  private onCreateButtonClick = () => {
    navigate(withPrefix(this.editWindowPath))
  }

  private onUpdateButtonClick = () => {
    this.loadData()
  }

  private loadData = () => {
    this.props.dispatch(fetchPharmaciesNetworks())
  }

  private onChangeRowsPerPage = (pageSize: number) => {
    this.props.dispatch(onChangePageSize(pageSize))
  }

  private onChangePage = (pageIndex: number) => {
    this.props.dispatch(onChangePageIndex(pageIndex))
  }

  private onChangeRowsPerPage = (pageSize: number) => {
    this.props.dispatch(onChangePageSize(pageSize))
  }

  private onChangePage = (pageIndex: number) => {
    this.props.dispatch(onChangePageIndex(pageIndex))
  }

  private syncComponent = () => {
    const syncButton = (
      <Button variant="contained" color="primary" onClick={() => this.props.dispatch(syncPharmaciesNetworks())}>
        Синхронизация
      </Button>
    )
    const asyncWorkProps: IAsyncWorkContext = {
      workStatus: this.props.syncStatus?.status,
      startWorkElement: syncButton,
    }
    return <AsyncWork {...asyncWorkProps} />
  }

  render() {
    return (
      <MaterialTable
        title="Аптечные сети"
        data={this.props.pharmaciesNetworks}
        icons={tableIcons}
        columns={this.columns}
        isLoading={this.props.loading}
        options={{
          search: false,
          emptyRowsWhenPaging: false,
          showTextRowsSelected: false,
          pageSize: this.props.paging.pageSize,
          pageSizeOptions: [25, 50, 75],
        }}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div className={styles.toolbarWrapper}>
                <div className={styles.buttonWrapper}>
                  <Button variant="contained" color="primary" onClick={this.onCreateButtonClick}>
                    Добавить
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button variant="contained" color="primary" onClick={this.onUpdateButtonClick}>
                    Обновить
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <this.syncComponent />
                </div>
              </div>
            </div>
          ),
          Pagination: (props) => (
            <TablePagination
              {...props}
              count={this.props.rowCount}
              page={this.props.paging.pageIndex}
              onChangePage={(e, page) => {
                this.onChangePage(page)
              }}
            />
          ),
        }}
      />
    )
  }
}

const mapStateToProps = (store: IAppState): IPharmaciesNetworksesContext => {
  return {
    dispatch: store.dispatch,
    loading: store.pharmaciesNetworks.list.loading,
    pharmaciesNetworks: store.pharmaciesNetworks.list.items,
    rowCount: store.pharmaciesNetworks.list.rowCount,
    paging: store.pharmaciesNetworks.list.paging,
    syncStatus: store.pharmaciesNetworks.sync.workStatus,
  }
}

export default connect(mapStateToProps, null)(PharmaciesNetworksDictionary)
