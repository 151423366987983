import React from 'react'
import { connect } from 'react-redux'
import { initialize, reset, submit } from 'redux-form'

import AsyncWork, { IAsyncWorkContext } from '@external/rp.ui/components/AsyncWork/AsyncWork'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { Button } from '@material-ui/core'

import { CurrencyForm, ICurrencyForm } from '../../../proto/models'
import { clearTimer, syncCurrency } from '../../../reducers/currency/CurrencySyncReducer'
import { IAppState } from '../../../reducers/rootReducer'
import CurrencyEditForm, { ICurrencyEditWindowContext } from './CurrencyEditWindow'
import CurrencyTable from './CurrencyTable'

const url = 'dictionaries/currencies'

const CurrencyDictionary = (props: any) => {
  React.useEffect(() => {
    return () => {
      props.dispatch(clearTimer())
    }
  }, [])
  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)
  const windowParams: ICurrencyEditWindowContext = {
    createButtonClickHandler: () => {
      props.dispatch(submit('currencyEditForm'))
    },
    handleClose: () => {
      setOpen(false)
      props.dispatch(
        initialize('currencyEditForm', {
          id: [],
          name: '',
          code: '',
        })
      )
    },
    isOpen: open,
  }
  const syncComponent = () => {
    const syncButton = (
      <Button variant="contained" color="primary" onClick={() => props.dispatch(syncCurrency())}>
        Синхронизация
      </Button>
    )
    const asyncWorkProps: IAsyncWorkContext = {
      workStatus: props.syncStatus,
      startWorkElement: syncButton,
    }
    return <AsyncWork {...asyncWorkProps} />
  }

  return (
    <>
      <CurrencyEditForm
        {...windowParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            var data = CurrencyForm.create({
              id: values.id,
              name: values.name,
              code: values.code,
            })

            const task =
              values.id?.length > 0
                ? ProtoClient.post<ICurrencyForm>(url, data, CurrencyForm, CurrencyForm)
                : ProtoClient.put<ICurrencyForm>(url, data, CurrencyForm, CurrencyForm)

            await task
            tableRef.current && tableRef.current.onQueryChange()
            setOpen(false)
            dispatch(
              initialize('currencyEditForm', {
                id: [],
                name: '',
                code: '',
              })
            )
          }
        }}
      />
      <CurrencyTable
        tableRef={tableRef}
        onDeleteButtonClick={(rowData) => ProtoClient.delete(url, GuidHelper.toString(rowData.id))}
        onEditButtonClick={(rowData) => {
          props.dispatch(
            initialize('currencyEditForm', {
              id: rowData.id,
              name: rowData.name,
              code: rowData.code,
            })
          )
          setOpen(true)
        }}
        onAddButtonClick={() => {
          setOpen(true)
        }}
        syncComponent={syncComponent()}
        url={url}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    syncStatus: store.currencies.sync.workStatus,
  }
}

export default connect(mapStateToProps, null)(CurrencyDictionary)
