import React from 'react'
import { connect } from 'react-redux'
import { initialize, submit } from 'redux-form'

import AsyncWork, { IAsyncWorkContext } from '@external/rp.ui/components/AsyncWork/AsyncWork'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { Button } from '@material-ui/core'

import { clearTimer, syncCountryCallingCode } from '../../../reducers/countryCallingCode/countryCallingCodeReducer'
import { IAppState } from '../../../reducers/rootReducer'
import CountryCallingCodeEditForm, { ICountryCallingCodeEditWindowContext } from './CountryCallingCodeEditWindow'
import CountryCallingCodeTable from './CountryCallingCodeTable'
import { CountryCallingCode, ICountryCallingCode } from 'src/proto/models'

const url = 'dictionaries/country-calling-codes'

const saveOrUpdate = async (values, tableRef, dispatch, setOpen) => {
  const data = CountryCallingCode.create({
    id: values.id,
    callingCode: values.callingCode,
    countryNameId: values.countryName.id,
  })

  const task =
    values.id?.length > 0
      ? ProtoClient.post<ICountryCallingCode>(url, data, CountryCallingCode, CountryCallingCode)
      : ProtoClient.put<ICountryCallingCode>(url, data, CountryCallingCode, CountryCallingCode)

  await task
  tableRef.current && tableRef.current.onQueryChange()
  setOpen(false)
  dispatch(
    initialize('CountryCallingCodeEditForm', {
      id: [],
      name: '',
      code: '',
    })
  )
}

const CountryCallingCodeDictionary = (props: any) => {
  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    return () => {
      props.dispatch(clearTimer())
    }
  }, [])

  const syncComponent = () => {
    const syncButton = (
      <Button variant="contained" color="primary" onClick={() => props.dispatch(syncCountryCallingCode())}>
        Синхронизация
      </Button>
    )
    const asyncWorkProps: IAsyncWorkContext = {
      workStatus: props.syncStatus,
      startWorkElement: syncButton,
    }
    return <AsyncWork {...asyncWorkProps} />
  }

  const windowParams: ICountryCallingCodeEditWindowContext = {
    createButtonClickHandler: () => {
      props.dispatch(submit('CountryCallingCodeEditForm'))
    },
    handleClose: () => {
      setOpen(false)
      props.dispatch(
        initialize('CountryCallingCodeEditForm', {
          id: null,
          country: null,
          callingCode: null,
        })
      )
    },
    isOpen: open,
    url: url,
  }

  return (
    <>
      <CountryCallingCodeEditForm
        {...windowParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            await saveOrUpdate(values, tableRef, dispatch, setOpen)
          }
        }}
      />
      <CountryCallingCodeTable
        tableRef={tableRef}
        onDeleteButtonClick={(rowData) => ProtoClient.delete(url, GuidHelper.toString(rowData.id))}
        onEditButtonClick={(rowData) => {
          props.dispatch(
            initialize('CountryCallingCodeEditForm', {
              id: rowData.id,
              callingCode: rowData.callingCode,
              countryName: {
                id: rowData.countryNameId,
                name: rowData.countryName,
              },
            })
          )
          setOpen(true)
        }}
        onAddButtonClick={() => {
          setOpen(true)
        }}
        url={url}
        syncComponent={syncComponent()}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    syncStatus: store.countryCallingCodes.sync.workStatus,
  }
}

export default connect(mapStateToProps, null)(CountryCallingCodeDictionary)
