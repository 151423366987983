import { AuthError } from '../exceptions/authError'
import { AuthHelper } from '../helpers/authHelper'
import { HttpHelper } from '../helpers/httpHelper'
import { ProtoHelper } from '../helpers/protoHelper'

export class AuthProtoClient {
  private constructor() {}

  private static apiPrefix = '/auth/api/'

  public static async post<TResult>(
    relativeUrl: string,
    data: any,
    requestProtoType: any,
    responseProtoType: any
  ): Promise<TResult> {
    const url = HttpHelper.constructUrl(this.apiPrefix, relativeUrl)
    const options = ProtoHelper.setOptions(HttpHelper.defaultOptions)
    const request = HttpHelper.postBefore(url, data, requestProtoType, options)

    const response = await fetch(request)

    if (AuthHelper.isAuthFailed(response)) {
      throw new AuthError()
    }

    return ProtoHelper.requestAfter<TResult>(response, responseProtoType)
  }
}
