import categorySyncReducer, { ICategorySyncState } from './categorySyncReducer'
import { combineReducers } from 'redux'

export const rootCategoryReducer = combineReducers({
  sync: categorySyncReducer,
})

export interface ICategoryState {
  sync: ICategorySyncState
}
