import classNames from 'classnames'
import React from 'react'

import { TextField } from '@material-ui/core'

import styles from './TextInput.module.scss'

interface ITextInputProps {
  input?: any
  placeholder?: string
  required?: boolean
  className?: string
  fullWidth?: boolean
  size?: 'small' | 'medium'
}

const TextInput = (props: ITextInputProps) => {
  const { input, placeholder, required, className, size, fullWidth } = props

  return (
    <div className={styles.textInputWrapper}>
      <TextField
        fullWidth={fullWidth}
        size={size}
        classes={{ root: 'textInput' }}
        id="outlined-basic"
        variant="outlined"
        {...input}
        placeholder={placeholder}
        className={className}
        required={required}
        onChange={(value: any) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
      />
    </div>
  )
}

export default TextInput
