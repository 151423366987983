export class GuidHelper {
  private static byteToHex = []
  private static hexToByte = {}

  public static init() {
    for (let i = 0; i < 256; i++) {
      GuidHelper.byteToHex[i] = (i + 0x100).toString(16).substr(1).toUpperCase()
      GuidHelper.hexToByte[GuidHelper.byteToHex[i].toUpperCase()] = i
    }
  }

  public static parse(s): Uint8Array {
    const buf = []
    let i = 0

    s.toUpperCase().replace(/[0-9A-F]{2}/g, (oct) => {
      if (i < 16) {
        let j = i

        switch (i) {
          case 0:
            j = 3
            break
          case 1:
            j = 2
            break
          case 2:
            j = 1
            break
          case 3:
            j = 0
            break
          case 4:
            j = 5
            break
          case 5:
            j = 4
            break
          case 6:
            j = 7
            break
          case 7:
            j = 6
            break
        }

        buf[j] = GuidHelper.hexToByte[oct]
        i++
      }
    })

    // Zero out remaining bytes if string was short
    while (i < 16) {
      buf[i++] = 0
    }

    return new Uint8Array(buf)
  }

  public static toString(buf: Uint8Array): string {
    let i = 8
    const bth = GuidHelper.byteToHex
    return (
      bth[buf[3]] +
      bth[buf[2]] +
      bth[buf[1]] +
      bth[buf[0]] +
      '-' +
      bth[buf[5]] +
      bth[buf[4]] +
      '-' +
      bth[buf[7]] +
      bth[buf[6]] +
      '-' +
      bth[buf[i++]] +
      bth[buf[i++]] +
      '-' +
      bth[buf[i++]] +
      bth[buf[i++]] +
      bth[buf[i++]] +
      bth[buf[i++]] +
      bth[buf[i++]] +
      bth[buf[i++]]
    )
  }

  public static equals(a: Uint8Array, b: Uint8Array): boolean {
    if (!a || !b || a.length !== b.length) {
      return false
    }

    for (let i = a.length; -1 < i; i -= 1) {
      if (a[i] !== b[i]) {
        return false
      }
    }
    return true
  }

  public static isEmpty(a: Uint8Array): boolean {
    if (!a || a.length === 0) {
      return true
    }

    if (a.find((i) => i !== 0)) {
      return false
    }

    return true
  }
}

GuidHelper.init()
