import React from 'react'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'

import { TextField } from '@external/rp.ui/components/MaterialReduxForm'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
} from '@material-ui/core'

import { ILanguageForm } from '../../../proto/models'

export interface ILanguageEditWindowContext {
  handleClose: (values: any) => void
  createButtonClickHandler: (e: any) => void
  isOpen: boolean
}

const required = (value: any) => (value ? undefined : 'Не заполнено обязательное поле')

const minLength = (value: string) => (value && value.length < 3 ? 'Код должен содержать 3 символа' : undefined)

const EditForm = (
  props: ILanguageEditWindowContext & InjectedFormProps<ILanguageForm, ILanguageEditWindowContext, string>
) => {
  const { handleSubmit, submitting } = props

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose} fullWidth>
      <DialogTitle>Валюта</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Наименование на английском</FormLabel>
              <Field
                placeholder={'Введите наименование языка на английском'}
                name={'internationalName'}
                component={TextField}
                inputProps={{ maxLength: 255 }}
                fullWidth
                validate={required}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Наименование на языке оригинала</FormLabel>
              <Field
                placeholder={'Введите наименование на языке оригинала'}
                name={'nativeName'}
                component={TextField}
                inputProps={{ maxLength: 255 }}
                fullWidth
                validate={required}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Буквенный код</FormLabel>
              <Field
                placeholder={'Введите буквенный код'}
                name={'code'}
                inputProps={{ maxLength: 3 }}
                component={TextField}
                validate={[required, minLength]}
                fullWidth
              />
            </FormControl>
          </Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.createButtonClickHandler} disabled={submitting}>
          Продолжить
        </Button>

        <Button variant="contained" color="primary" onClick={props.handleClose}>
          Назад
        </Button>
      </DialogActions>
    </Dialog>
  )
}

let LanguageEditForm = reduxForm<ILanguageForm, ILanguageEditWindowContext>({
  form: 'languageEditForm',
})(EditForm)

export default LanguageEditForm
