import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import styles from './Styles.module.scss'
import { Dispatch } from 'redux'
import { IAppState } from '../../../../reducers/rootReducer'
import { connect } from 'react-redux'
import TextInput from '../../../Input'

interface IEditFormContext {
  dispatch: Dispatch
}

class EditForm extends Component<IEditFormContext> {

  private isValidPharmaciesNetwork = (value: any) => {
    const template = new RegExp('^\\s*$')
    return !value || template.test(value) ? 'Поле обязательно к заполнению' : undefined
  }

  render() {
    return (
      <form>
        <div>
          <label>Наименование: </label>
          <Field
            name={'name'}
            placeholder={'Имя сети аптек'}
            component={TextInput}
            type={'text'}
            required={true}
            validate={this.isValidPharmaciesNetwork}
            className={styles.form_item}
          />
        </div>
      </form>
    )
  }
}

const mapStateToProps = (store: IAppState) => {
  let initialValues = {}

  if (store.pharmaciesNetworks.edit.data) {
    initialValues = {
      id: store.pharmaciesNetworks.edit.data.id,
      name: store.pharmaciesNetworks.edit.data.name,
    }
  }

  return {
    dispatch: store.dispatch,
    currentForm: store.form,
    initialValues: initialValues,
  }
}

const editReduxForm = reduxForm({
  form: 'pharmaciesNetworkEditForm',
  enableReinitialize: true,
})(EditForm)

const connectReduxForm = connect(mapStateToProps)(editReduxForm)

export default connectReduxForm
