import { IListState } from '../../data/state'
import { IPharmacies, IPharmacy, PagingAndSort, Pharmacies } from '../../proto/models'
import { Dispatch } from 'redux'
import { IAppState } from '../rootReducer'
import { ProtoClient } from '@external/rp.ui/utils/protoClient'

const pharmacyUrl = 'dictionaries/pharmacies'

const defaultPaging = {
  pageSize: 20,
  pageIndex: 0,
}

const defaultSorting = {
  sortColumn: 'CountryName',
  sortDirection: 'asc',
}

const initialState: IListState<IPharmacy> = {
  loading: false,
  items: [],
  rowCount: 0,
  paging: { ...defaultPaging },
  sorting: { ...defaultSorting },
}

const LOADING_PHARMACIES = 'LOADING_PHARMACIES'
export const loadingPharmacies = () => ({
  type: LOADING_PHARMACIES,
})

const PHARMACIES_LOADED = 'PHARMACIES_LOADED'
export const pharmaciesLoaded = (pharmacies: IPharmacies) => ({
  type: PHARMACIES_LOADED,
  items: pharmacies.items,
  rowCount: pharmacies.rowCount,
})

export const fetchPharmacies = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  dispatch(loadingPharmacies())

  const data = getState().pharmacies.list
  const paging = data.paging
  const sorting = data.sorting

  const pagingAndSort = PagingAndSort.create({ ...paging, ...sorting })

  const pharmacies = await ProtoClient.get<IPharmacies>(pharmacyUrl, Pharmacies, pagingAndSort)

  return dispatch(pharmaciesLoaded(pharmacies))
}

const CHANGE_PER_PAGE = 'CHANGE_PER_PAGE'
const changePageSize = (pageSize: number) => ({
  type: CHANGE_PER_PAGE,
  payload: pageSize,
})

export const onChangePageSize = (pageSize: number) => (dispatch: Dispatch) => {
  dispatch(changePageSize(pageSize))

  return dispatch(fetchPharmacies())
}

const CHANGE_PAGE_INDEX = 'CHANGE_PAGE_INDEX'
const changePageIndex = (pageIndex: number) => ({
  type: CHANGE_PAGE_INDEX,
  payload: pageIndex,
})

export const onChangePageIndex = (pageIndex: number) => (dispatch: Dispatch) => {
  dispatch(changePageIndex(pageIndex))

  return dispatch(fetchPharmacies())
}

const ORDER_CHANGE = 'ORDER_CHANGE'
const orderChange = (sortColumn: string, sortDirection: string) => ({
  type: ORDER_CHANGE,
  payload: {
    sortColumn,
    sortDirection,
  },
})

export const onOrderChange = (sortColumn: string, sortDirection: string) => (dispatch: Dispatch) => {
  if (sortColumn === '' || sortDirection === '')
    dispatch(orderChange(defaultSorting.sortColumn, defaultSorting.sortDirection))
  else dispatch(orderChange(sortColumn, sortDirection))

  return dispatch(fetchPharmacies())
}

const REMOVE_PHARMACY = 'REMOVE_PHARMACY'
export const removePharmacy = (id: string) => async (dispatch: Dispatch, getState: () => IAppState) => {
  dispatch({ type: REMOVE_PHARMACY, payload: true })

  await ProtoClient.delete(pharmacyUrl, id)

  dispatch(fetchPharmacies())
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING_PHARMACIES:
      return {
        ...state,
        loading: true,
        rowCount: 0,
        items: [],
        paging: { ...state.paging },
        sorting: { ...state.sorting },
      }
    case PHARMACIES_LOADED:
      return {
        ...state,
        loading: false,
        rowCount: action.rowCount,
        items: action.items,
        paging: { ...state.paging },
        sorting: { ...state.sorting },
      }
    case CHANGE_PER_PAGE:
      return {
        ...state,
        paging: { ...state.paging, pageSize: action.payload, pageIndex: 0 },
      }
    case CHANGE_PAGE_INDEX:
      return {
        ...state,
        paging: { ...state.paging, pageIndex: action.payload },
      }
    case REMOVE_PHARMACY:
      return {
        ...state,
        loading: true,
        rowCount: 0,
        items: [],
        paging: { ...state.paging },
        sorting: { ...state.sorting },
      }
    case ORDER_CHANGE:
      return {
        ...state,
        sorting: action.payload,
      }
    default:
      return state
  }
}
