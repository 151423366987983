import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React, { ReactElement } from 'react'

import RecordActionDialog, { RecordActionParams } from '@external/rp.ui/components/Modal/RecordActionModalDialog'
import { LocalizationHelper } from '@external/rp.ui/helpers/LocalizationHelper'
import { AuthError, AuthHelper, ProtoClient } from '@external/rp.ui/utils/protoClient'
import { Box, Button, Grid } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { Categories, ICategories, ICategoryList, PagingAndSort } from '../../../proto/models'
import { tableIcons } from '../../shared/tableIcons'

interface ITableProps {
  onEditButtonClick: (rowData: ICategoryList) => void
  onDeleteButtonClick: (rowData: ICategoryList) => void
  onAddButtonClick: () => void
  syncComponent: ReactElement
  url: string
  tableRef: any
}

const fetchData = async (query: Query<ICategoryList>, url: string): Promise<QueryResult<ICategoryList>> => {
  const paging = PagingAndSort.create({
    pageIndex: query.page,
    pageSize: query.pageSize,
    sortColumn: query.orderBy?.field ?? 'name',
    sortDirection: (query.orderDirection as string) === '' ? 'asc' : query.orderDirection,
    searchQuery: query.search,
  })

  try {
    const categories = await ProtoClient.get<ICategories>(url, Categories, paging)

    return {
      data: categories?.items ?? [],
      page: query.page,
      totalCount: categories?.rowCount ?? 0,
    }
  } catch (error) {
    if (error instanceof AuthError) {
      AuthHelper.redirect(error.fullCallbackUrl)
    }
    throw error
  }
}

const Table = (props: ITableProps) => {
  const [isLoading, setLoading] = React.useState(false)

  const ConfirmDeletionModalButton = (props: {
    rowData: any
    onAgreeBtnClick: any
    tableRef: any
  }): React.ReactElement<RecordActionParams> => {
    const modalParams: RecordActionParams = {
      agreeCallbackArgs: props.rowData,
      button: (
        <Fab onClick={async () => {}} size="small" color="secondary" aria-label="delete" style={{ margin: 0 }}>
          <DeleteIcon />
        </Fab>
      ),
      title: '',
      body: 'Вы уверены, что хотите Удалить \n запись справочника?',
      agreeString: 'Да',
      disagreeString: 'Нет',
      agreeCallback: async (rowData) => {
        setLoading(true)
        await props.onAgreeBtnClick(rowData)
        props.tableRef.current && props.tableRef.current.onQueryChange()
        setLoading(false)
      },
      disagreeCallback(): void {},
    }
    return RecordActionDialog(modalParams)
  }

  const editColumnRenderer = (rowData: ICategoryList): JSX.Element => (
    <Fab
      onClick={() => props.onEditButtonClick(rowData)}
      size="small"
      color="primary"
      aria-label="edit"
      style={{ margin: 0 }}
    >
      <EditIcon />
    </Fab>
  )
  const deleteColumnRenderer = (rowData: ICategoryList): JSX.Element => (
    <ConfirmDeletionModalButton
      onAgreeBtnClick={props.onDeleteButtonClick}
      rowData={rowData}
      tableRef={props.tableRef}
    />
  )
  const toolbarRenderer: React.ComponentType<any> = (toolbarProps) => (
    <Box>
      <MTableToolbar {...toolbarProps} />
      <Box ml={2}>
        <Grid container direction={'row'} alignItems={'flex-start'}>
          <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={props.onAddButtonClick}>
              Добавить
            </Button>
          </Grid>
          <Grid item xs={1}>
            {props.syncComponent}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )

  return (
    <MaterialTable<ICategoryList>
      title="Категории"
      isLoading={isLoading}
      tableRef={props.tableRef}
      localization={LocalizationHelper.GetLocalization()}
      icons={tableIcons}
      columns={[
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          render: editColumnRenderer,
        },
        { title: 'Наименование', field: 'name', sorting: true },
        { title: 'Описание', field: 'description', sorting: true },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'delete',
          render: deleteColumnRenderer,
        },
      ]}
      options={{
        sorting: true,
        search: false,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchData(query, props.url)}
      components={{
        Toolbar: toolbarRenderer,
      }}
    />
  )
}

export default Table
