import { WebClient } from '@external/rp.ui/utils/protoClient'
import { SyntheticEvent } from 'react'

export class UploaderService {
  public upload = (event: SyntheticEvent, relativeUrl: string) => {
    const file = event.target.files[0]

    const formData = new FormData()

    formData.append('file', file, file.name)

    const options = { body: formData }

    WebClient.request(relativeUrl, 'POST', options)

    event.target.form.reset()
  }
}
