import { ProtoClient } from "@external/rp.ui/utils/protoClient"
import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { initialize, SubmissionError } from "redux-form"
import { DrugCreateResponse, DrugDto, DrugFormDto, IDrugCreateResponse, IDrugFormDto } from "src/proto/models"

export type DrugFormOwnProps = {
  drugNameId?: string,
}

export type DrugEditFormState = {
  loading: boolean,
  errorMessage?: string
}

const initialState: DrugEditFormState = {
  loading: false,
  errorMessage: null,
}

const SET_DRUG_LOADING = 'SET_DRUG_LOADING'
export const setDrugLoading = createAction<boolean>(SET_DRUG_LOADING)

const FETCH_DRUG = 'FETCH_DRUG'
export const fetchDrug = createAsyncThunk<void, string>(FETCH_DRUG, async (drugNameId, api) => {
  const drugFormDto = await ProtoClient.get<IDrugFormDto>('dictionaries/drugs/edit', DrugFormDto, { drugNameId })

  if (drugFormDto.drugNameSynonyms) {
    drugFormDto.drugNameSynonyms.push(null)
  } else {
    drugFormDto.drugNameSynonyms = [null]
  }

  api.dispatch(initialize('drugEditForm', drugFormDto))
})

const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const setErrorMessage = createAction<string>(SET_ERROR_MESSAGE)

export const createDrug = async (drug: IDrugFormDto, dispatch: any, props: DrugFormOwnProps) => {
  dispatch(setErrorMessage(null))

  let drugNameSynonyms: string[] = [];
  if (drug.drugNameSynonyms) {
    drugNameSynonyms = drug.drugNameSynonyms.filter((synonym) => !!synonym);
  }

  const drugToSave = DrugFormDto.create({ ...drug, drugNameSynonyms })

  let response: IDrugCreateResponse = null
  if (!props.drugNameId) {
    response = await ProtoClient.put<IDrugCreateResponse>('dictionaries/drugs', drugToSave, DrugFormDto, DrugCreateResponse)
  }else {
    response = await ProtoClient.post<IDrugCreateResponse>('dictionaries/drugs/update', drugToSave, DrugFormDto, DrugCreateResponse)
  }

  if (!response.success) {
    throw new SubmissionError({ _error: response.message })
  }
}

export default createReducer(initialState, {
  [SET_DRUG_LOADING]: (state, action) => {
    state.loading = action.payload
  },
  [SET_ERROR_MESSAGE]: (state, action) => {
    state.errorMessage = action.payload
  },
  [fetchDrug.pending.toString()]: (state, action) => {
    state.loading = true
  },
  [fetchDrug.rejected.toString()]: (state, action) => {
    state.loading = false
  },
  [fetchDrug.fulfilled.toString()]: (state, action) => {
    state.loading = false
  },
})