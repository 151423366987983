import { Button, FormControl, Grid } from '@material-ui/core'
import useAutocomplete, { createFilterOptions } from '@material-ui/lab/useAutocomplete'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  change,
  Field,
  FormErrors,
  formValueSelector,
  getFormSyncErrors,
  InjectedFormProps,
  reduxForm,
  Validator,
  WrappedFieldInputProps,
  WrappedFieldMetaProps,
} from 'redux-form'

import CustomInput from '../../../../components/CustomInput'
import { fetchCountries } from '../../../../reducers/pharmacy/editForm/countryReducer'
import { fetchDistricts } from '../../../../reducers/pharmacy/editForm/districtReducer'
import { fetchHouses, housesLoaded } from '../../../../reducers/pharmacy/editForm/houseReducer'
import { fetchPharmaciesNetwork } from '../../../../reducers/pharmacy/editForm/pharmaciesNetworkReducer'
import { fetchRegions } from '../../../../reducers/pharmacy/editForm/regionReducer'
import { fetchSettlements } from '../../../../reducers/pharmacy/editForm/settlementReducer'
import { fetchStreets, streetsLoaded } from '../../../../reducers/pharmacy/editForm/streetReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import { ISelectState } from '../../../../reducers/types/types'
import CustomAutocompleteInput from '../../../CustomAutocompleteInput'
import styles from './PharmacyEditForm.module.scss'

const selector = formValueSelector('pharmacyEditForm')

interface IPharmacyEditFormState {
  country: ISelectState
  region: ISelectState
  settlement: ISelectState
  district: ISelectState
  street: ISelectState
  house: ISelectState
  pharmaciesNetwork: ISelectState
}

interface IEditFormOwnProps {
  onBackBtnClick: () => void
  handleSubmit: () => void
  editMode: boolean
}

interface IEditFormStateProps {
  dispatch: AppDispatch
  pharmaciesNetwork: ISelectState[]
  countries: ISelectState[]
  regions: ISelectState[]
  settlements: ISelectState[]
  districts: ISelectState[]
  streets: ISelectState[]
  houses: ISelectState[]
  formState: IPharmacyEditFormState
  formErrors: FormErrors
  initialValues: unknown
}

type PharmacyEditFormDispatchProps = {
  changeField: (name: string, value: ISelectState) => void
}

const WIDTH_LABEL = 2
const WIDTH_FIELD = 9

const resetFields = (fields: string[], changeField: (name: string, value: ISelectState) => void) => {
  fields.map((item) => changeField(item, null))
}

const CustomFilter = createFilterOptions<ISelectState>()

interface ICustomFieldProps {
  placeholder: string
  selectHandler?: (value: ISelectState | string) => void
  input: WrappedFieldInputProps
  meta: WrappedFieldMetaProps
  addNewField?: boolean
  disabled: boolean
  changeHandler: (name: string, value: ISelectState) => void
  fetchedData: ISelectState[]
  value?: ISelectState
}

const CustomField = (props: ICustomFieldProps) => {
  const {
    placeholder,
    selectHandler,
    input: { onChange, name, value },
    meta,
    addNewField,
    changeHandler,
    disabled,
    fetchedData,
  } = props

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete<ISelectState>({
      options: fetchedData,
      filterOptions: (options, params) => {
        const filtered = CustomFilter(options, params)

        if (params.inputValue !== '' && addNewField) {
          filtered.push({
            label: params.inputValue,
            value: null,
          })
        }

        return name === 'settlement' ? filtered : filtered.filter((item, index) => index <= 10)
      },
      getOptionLabel: (option) => option.label,
      onChange: (event, newValue) => {
        onChange(newValue)

        if (typeof selectHandler === 'function') {
          selectHandler(newValue)
        }

        if (typeof changeHandler === 'function') {
          if (name === 'country') {
            resetFields(['region', 'settlement', 'district', 'street', 'house'], changeHandler)
          }

          if (name === 'region') {
            resetFields(['settlement', 'district', 'street', 'house'], changeHandler)
          }

          if (name === 'settlement') {
            resetFields(['district', 'street', 'house'], changeHandler)
          }

          if (name === 'district') {
            resetFields(['street', 'house'], changeHandler)
          }

          if (name === 'street') {
            resetFields(['house'], changeHandler)
          }
        }
      },
      getOptionSelected: () => true,
      value: value,
      openOnFocus: true,
    })

  return (
    <CustomAutocompleteInput
      meta={meta}
      groupedOptions={groupedOptions}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      getListboxProps={getListboxProps}
      getOptionProps={getOptionProps}
      getOptionLabel={(option) => option.label}
      placeholder={placeholder}
      type={'text'}
      disabled={disabled}
      size="normal"
      isReserveLabelError={false}
    />
  )
}

const required: Validator = (value) => {
  return !value ? 'Обязательное' : undefined
}

const requiredAutoComplete: Validator = (value) => {
  return value && value.label ? undefined : 'Обязательное'
}

type EditFormProps = IEditFormOwnProps &
  IEditFormStateProps &
  PharmacyEditFormDispatchProps &
  InjectedFormProps<IPharmacyEditFormState, IEditFormOwnProps, string>

class EditForm extends Component<EditFormProps> {
  constructor(props: EditFormProps) {
    super(props)

    this.getCountries()
    this.getPharmaciesNetwork()
  }

  render() {
    return (
      <form className={styles.form} onSubmit={this.props.handleSubmit}>
        <Grid container spacing={3} direction={'column'} alignItems={'flex-start'} justify={'flex-start'}>
          <Grid container item direction={'row'} alignItems={'center'} justify={'flex-start'} xs>
            <Grid item xs={WIDTH_LABEL}>
              <label>*Наименование:</label>
            </Grid>
            <Grid item xs={WIDTH_FIELD}>
              <FormControl fullWidth>
                <Field
                  component={CustomInput}
                  name="name"
                  placeholder="Введите наименование аптеки"
                  size="normal"
                  isReserveLabelError={false}
                  validate={required}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item direction={'row'} alignItems={'center'} justify={'flex-start'} xs>
            <Grid item xs={WIDTH_LABEL}>
              <label>Аптечная сеть:</label>
            </Grid>
            <Grid item xs={WIDTH_FIELD}>
              <FormControl fullWidth>
                <Field
                  placeholder="Выберите аптечную сеть"
                  name="pharmaciesNetwork"
                  component={CustomField}
                  fetchedData={this.props.pharmaciesNetwork ?? []}
                  disabled={!this.props.pharmaciesNetwork}
                  addNewField={true}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item direction={'row'} alignItems={'center'} justify={'flex-start'} xs>
            <Grid item xs={WIDTH_LABEL}>
              <label>*Страна:</label>
            </Grid>
            <Grid item xs={WIDTH_FIELD}>
              <FormControl fullWidth>
                <Field
                  placeholder="Выберите страну"
                  name="country"
                  component={CustomField}
                  fetchedData={this.props.countries ?? []}
                  selectHandler={this.countryChangeHandler}
                  addNewField={false}
                  changeHandler={this.props.changeField}
                  validate={[requiredAutoComplete]}
                  disabled={!this.props.countries}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item direction={'row'} alignItems={'center'} justify={'flex-start'} xs>
            <Grid item xs={WIDTH_LABEL}>
              <label>*Регион:</label>
            </Grid>
            <Grid item xs={WIDTH_FIELD}>
              <FormControl fullWidth>
                <Field
                  placeholder="Выберите регион"
                  name="region"
                  component={CustomField}
                  fetchedData={this.props.regions ?? []}
                  selectHandler={this.regionChangeHandler}
                  disabled={!this.props.formState.country?.label}
                  addNewField={true}
                  changeHandler={this.props.changeField}
                  validate={[requiredAutoComplete]}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item direction={'row'} alignItems={'center'} justify={'flex-start'} xs>
            <Grid item xs={WIDTH_LABEL}>
              <label>Населенный пункт:</label>
            </Grid>
            <Grid item xs={WIDTH_FIELD}>
              <FormControl fullWidth>
                <Field
                  placeholder="Выберите населенный пункт"
                  name="settlement"
                  component={CustomField}
                  fetchedData={this.props.settlements ?? []}
                  selectHandler={this.settlementChangeHandler}
                  disabled={!this.props.formState.region?.label}
                  addNewField={true}
                  changeHandler={this.props.changeField}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item direction={'row'} alignItems={'center'} justify={'flex-start'} xs>
            <Grid item xs={WIDTH_LABEL}>
              <label>Район:</label>
            </Grid>
            <Grid item xs={WIDTH_FIELD}>
              <FormControl fullWidth>
                <Field
                  placeholder="Выберите район"
                  name="district"
                  component={CustomField}
                  fetchedData={this.props.districts ?? []}
                  disabled={!this.props.formState.settlement?.label}
                  addNewField={true}
                  changeHandler={this.props.changeField}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item direction={'row'} alignItems={'center'} justify={'flex-start'} xs>
            <Grid item xs={WIDTH_LABEL}>
              <label>Улица:</label>
            </Grid>
            <Grid item xs={WIDTH_FIELD}>
              <FormControl fullWidth>
                <Field
                  placeholder="Выберите улицу"
                  name="street"
                  component={CustomField}
                  fetchedData={this.props.streets ?? []}
                  selectHandler={this.streetChangeHandler}
                  disabled={!this.props.formState.region?.label}
                  addNewField={true}
                  changeHandler={this.props.changeField}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item direction={'row'} alignItems={'center'} justify={'flex-start'} xs>
            <Grid item xs={WIDTH_LABEL}>
              <label>*Дом:</label>
            </Grid>
            <Grid item xs={WIDTH_FIELD}>
              <FormControl fullWidth>
                <Field
                  placeholder="Выберите дом"
                  name="house"
                  component={CustomField}
                  fetchedData={this.props.houses ?? []}
                  disabled={!this.props.formState.region?.label}
                  addNewField={true}
                  validate={[requiredAutoComplete]}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item direction={'row'} spacing={3} alignItems={'stretch'} justify={'flex-start'}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={this.props.handleSubmit}
              disabled={this.props.submitting || this.props.invalid}
            >
              Сохранить
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={this.props.onBackBtnClick}>
              Назад
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  private getPharmaciesNetwork = () => {
    this.props.dispatch(fetchPharmaciesNetwork())
  }

  private getCountries = () => {
    this.props.dispatch(fetchCountries())
  }

  private getRegions = (value: ISelectState) => {
    if (value?.value && value?.value.length > 0) {
      this.props.dispatch(fetchRegions(value))
    }
  }

  private getSettlements = (country: ISelectState, region: ISelectState) => {
    if (country?.value && country?.value.length > 0 && region?.value && region?.value.length > 0) {
      this.props.dispatch(fetchSettlements(country, region))
    }
  }

  private getDistrict = (country: ISelectState, region: ISelectState, settlement: ISelectState) => {
    if (
      country?.value &&
      country?.value.length > 0 &&
      region?.value &&
      region?.value.length > 0 &&
      settlement?.value &&
      settlement?.value.length > 0
    ) {
      this.props.dispatch(fetchDistricts(country, region, settlement))
    }
  }

  private getStreets = (country: ISelectState, region: ISelectState, settlement?: ISelectState) => {
    if (
      country?.value &&
      country?.value.length > 0 &&
      region?.value &&
      region?.value.length > 0 &&
      ((settlement?.value && settlement?.value.length > 0) || settlement == null)
    ) {
      this.props.dispatch(fetchStreets(country, region, settlement))
    } else {
      this.props.dispatch(streetsLoaded({ SearchResults: [] }))
    }
  }

  private getHouses = (
    country: ISelectState,
    region: ISelectState,
    settlement?: ISelectState,
    street?: ISelectState
  ) => {
    if (
      country?.value &&
      country?.value.length > 0 &&
      region?.value &&
      region?.value.length > 0 &&
      ((settlement?.value && settlement?.value.length > 0) || settlement == null) &&
      ((street?.value && street?.value.length > 0) || street == null)
    ) {
      this.props.dispatch(fetchHouses(country, region, settlement, street))
    } else {
      this.props.dispatch(housesLoaded({ SearchResults: [] }))
    }
  }

  private countryChangeHandler = (value: ISelectState) => {
    this.getRegions(value)
  }

  private regionChangeHandler = (value: ISelectState) => {
    this.getSettlements(this.props.formState.country, value)
    this.getStreets(this.props.formState.country, value, null)
    this.getHouses(this.props.formState.country, value, null)
  }

  private settlementChangeHandler = (value: ISelectState) => {
    this.getDistrict(this.props.formState.country, this.props.formState.region, value)
    this.getStreets(this.props.formState.country, this.props.formState.region, value)
    this.getHouses(this.props.formState.country, this.props.formState.region, value)
  }

  private streetChangeHandler = (value: ISelectState) => {
    this.getHouses(this.props.formState.country, this.props.formState.region, this.props.formState.settlement, value)
  }
}

const mapStateToProps = (store: IAppState): IEditFormStateProps => {
  let initialValues = {}
  const formState = selector(
    store,
    'country',
    'region',
    'settlement',
    'district',
    'street',
    'house',
    'pharmaciesNetwork'
  )
  const formErrors = getFormSyncErrors('pharmacyEditForm')(store)

  if (store.pharmacies.edit.data) {
    const pharmacy = store.pharmacies.edit.data

    initialValues = {
      name: pharmacy.name,
      pharmaciesNetwork: { label: pharmacy.pharmaciesNetwork, value: pharmacy.pharmaciesNetworkId },
      country: { label: pharmacy.countryName, value: pharmacy.countryId },
      region: { label: pharmacy.regionName, value: pharmacy.regionId },
      settlement: {
        label: pharmacy.settlementName,
        value: pharmacy.settlementId,
      },
      district: { label: pharmacy.districtName, value: pharmacy.districtId },
      street: { label: pharmacy.streetName, value: pharmacy.streetId },
      house: { label: pharmacy.houseNumber, value: pharmacy.houseId },
    }
  }

  return {
    dispatch: store.dispatch,
    pharmaciesNetwork: store.pharmacies.editForm.pharmaciesNetwork.pharmaciesNetwork,
    countries: store.pharmacies.editForm.countries.countries,
    regions: store.pharmacies.editForm.regions.regions,
    settlements: store.pharmacies.editForm.settlements.settlements,
    districts: store.pharmacies.editForm.districts.districts,
    streets: store.pharmacies.editForm.streets.streets,
    houses: store.pharmacies.editForm.houses.houses,
    formState: formState ?? {},
    formErrors: formErrors,
    initialValues: initialValues,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    changeField: (name: string, value: ISelectState) => {
      dispatch(change('pharmacyEditForm', name, value))
    },
  }
}

const editReduxForm = reduxForm<
  IPharmacyEditFormState,
  IEditFormOwnProps & IEditFormStateProps & PharmacyEditFormDispatchProps
>({
  form: 'pharmacyEditForm',
  enableReinitialize: true,
})(EditForm)

const connectReduxForm = connect<IEditFormStateProps, PharmacyEditFormDispatchProps, IEditFormOwnProps, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(editReduxForm)

export default connectReduxForm
