import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  arrayPush,
  clearSubmitErrors,
  Field,
  FieldArray,
  Form,
  InjectedFormProps,
  reduxForm,
  WrappedFieldArrayProps,
} from 'redux-form'

import { TextField } from '@external/rp.ui/components/MaterialReduxForm'
import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { Button, CircularProgress, TextField as MuiTextField, Typography } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Alert, Autocomplete } from '@material-ui/lab'

import { CategorySearchResult, ICategorySearch, ICategorySearchResult, INosologyForm } from '../../../proto/models'
import {
  getNosology,
  NosologyEditFormState,
  saveNosology,
  setNosologyLoading,
} from '../../../reducers/nosologies/nosologyEditFormreducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'

const onSearchValueChange = async (search: string, url: string): Promise<ICategorySearch[]> => {
  if (!search || search === '') {
    return []
  }
  const res = await ProtoClient.get<ICategorySearchResult>(url + '/search-category', CategorySearchResult, {
    search: search,
  })
  return res?.items ?? []
}

const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 500)

const AutocompleteField = (props: any) => {
  const [options, setOptions] = React.useState<ICategorySearch[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const { onChange, value } = props.input

  return (
    <Autocomplete
      multiple={false}
      freeSolo
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setLoading(true)
          searchAPIDebounced(value, 'dictionaries/nosologies').then((res) => {
            setOptions(res)
            setLoading(false)
            setOpen(true)
          })
        }
        if (reason === 'clear') {
          setOptions([])
          setLoading(false)
        }
      }}
      loading={loading}
      getOptionSelected={(option, value) => option.name === value.name}
      options={options}
      renderOption={(option) => option.name}
      filterOptions={(options, state) => options}
      getOptionLabel={(option) => option.name}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      value={value}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          value={value?.name}
          placeholder={'Выберите категорию'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

const toUppercase = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

const renderSynonyms = ({ fields }: WrappedFieldArrayProps) => (
  <div>
    {fields.map((synonym, index) => (
      <Field key={index} name={synonym} component={TextField} normalize={toUppercase} variant="outlined" />
    ))}
  </div>
)

const NosologyEditWindow = (props: NosologyFormProps) => {
  const { loading, error, dispatch, id, handleSubmit, submitting, pristine } = props

  useEffect(() => {
    if (id) {
      dispatch(getNosology(id))
    } else {
      dispatch(arrayPush(formName, 'synonyms', null))
    }
    return () => {
      dispatch(setNosologyLoading(false))
    }
  }, [])

  const getAlert = () => {
    return (
      <div>
        <Collapse in={typeof error !== 'undefined'}>
          <Alert
            variant="filled"
            severity="error"
            style={{ marginBottom: '20px' }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch(clearSubmitErrors('nosologyEditForm'))
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {error}
          </Alert>
        </Collapse>
      </div>
    )
  }

  const onBackBtnClick = (e: any) => {
    navigateToDictionary()
  }

  const navigateToDictionary = () => {
    navigate('/nosologies')
  }

  return loading ? (
    <CircularProgress />
  ) : (
    <Form onSubmit={handleSubmit}>
      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '20px' }}
        onClick={onBackBtnClick}
        type="button"
      >
        Назад
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '20px', marginBottom: '20px' }}
        type="submit"
        disabled={submitting || pristine}
      >
        Сохранить
      </Button>
      {getAlert()}
      <Field
        variant="outlined"
        autoFocus={true}
        placeholder="Категория"
        name="category"
        component={AutocompleteField}
      />
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Typography variant="h6" noWrap>
          Нозология
        </Typography>
      </div>
      <Field variant="outlined" name="nosology" component={TextField} normalize={toUppercase} />
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Typography variant="h6" noWrap>
          Синонимы
        </Typography>
      </div>
      <FieldArray name="synonyms" component={renderSynonyms} />
    </Form>
  )
}

export type NosologyFormOwnProps = {
  path: string
  id?: string
}

type NosologyInjectedStateProps = {
  dispatch?: AppDispatch
  loading: boolean
}

type NosologyFormProps = InjectedFormProps<INosologyForm, NosologyFormOwnProps & NosologyInjectedStateProps> &
  NosologyFormOwnProps &
  NosologyInjectedStateProps

const formName = 'nosologyEditForm'

const editReduxForm = reduxForm<INosologyForm, NosologyFormOwnProps & NosologyInjectedStateProps>({
  form: formName,
  onSubmit: saveNosology,
  onSubmitSuccess: (result, dispatch, props) => {
    if (!props.id) {
      navigate(`/nosologies`)
    }
  },
  onChange: (values, dispatch, props, prev) => {
    if (
      values.synonyms &&
      prev.synonyms &&
      values.synonyms.length > 0 &&
      prev.synonyms.length > 0 &&
      values.synonyms.length === prev.synonyms.length &&
      values.synonyms[values.synonyms.length - 1] !== '' &&
      values.synonyms[values.synonyms.length - 1] !== prev.synonyms[values.synonyms.length - 1]
    ) {
      dispatch(arrayPush(formName, 'synonyms', null))
    }
  },
})(NosologyEditWindow)

const mapStateToProps = (store: IAppState): NosologyInjectedStateProps => {
  return {
    dispatch: store.dispatch,
    loading: store.nosologies.loading,
  }
}

const connectedForm = connect<NosologyInjectedStateProps, unknown, NosologyFormOwnProps, IAppState>(
  mapStateToProps,
  null
)(editReduxForm)

export default connectedForm
