import { combineReducers } from 'redux'

import countryReducer from './countryReducer'
import districtReducer from './districtReducer'
import houseReducer from './houseReducer'
import pharmaciesNetworkReducer from './pharmaciesNetworkReducer'
import regionReducer from './regionReducer'
import settlementReducer from './settlementReducer'
import streetReducer from './streetReducer'

export const rootPharmacyEditFormReducer = combineReducers({
  countries: countryReducer,
  regions: regionReducer,
  settlements: settlementReducer,
  districts: districtReducer,
  streets: streetReducer,
  houses: houseReducer,
  pharmaciesNetwork: pharmaciesNetworkReducer,
})
