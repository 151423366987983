/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.Login = (function() {
    
        /**
         * Properties of a Login.
         * @exports ILogin
         * @interface ILogin
         * @property {string|null} [username] Login username
         * @property {string|null} [password] Login password
         */
    
        /**
         * Constructs a new Login.
         * @exports Login
         * @classdesc Represents a Login.
         * @implements ILogin
         * @constructor
         * @param {ILogin=} [properties] Properties to set
         */
        function Login(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Login username.
         * @member {string} username
         * @memberof Login
         * @instance
         */
        Login.prototype.username = "";
    
        /**
         * Login password.
         * @member {string} password
         * @memberof Login
         * @instance
         */
        Login.prototype.password = "";
    
        /**
         * Creates a new Login instance using the specified properties.
         * @function create
         * @memberof Login
         * @static
         * @param {ILogin=} [properties] Properties to set
         * @returns {Login} Login instance
         */
        Login.create = function create(properties) {
            return new Login(properties);
        };
    
        /**
         * Encodes the specified Login message. Does not implicitly {@link Login.verify|verify} messages.
         * @function encode
         * @memberof Login
         * @static
         * @param {ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };
    
        /**
         * Encodes the specified Login message, length delimited. Does not implicitly {@link Login.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Login
         * @static
         * @param {ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Login message from the specified reader or buffer.
         * @function decode
         * @memberof Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Login();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Login message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Login message.
         * @function verify
         * @memberof Login
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Login.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.username != null && message.hasOwnProperty("username"))
                if (!$util.isString(message.username))
                    return "username: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };
    
        /**
         * Creates a Login message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Login
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Login} Login
         */
        Login.fromObject = function fromObject(object) {
            if (object instanceof $root.Login)
                return object;
            var message = new $root.Login();
            if (object.username != null)
                message.username = String(object.username);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };
    
        /**
         * Creates a plain object from a Login message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Login
         * @static
         * @param {Login} message Login
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Login.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.username = "";
                object.password = "";
            }
            if (message.username != null && message.hasOwnProperty("username"))
                object.username = message.username;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };
    
        /**
         * Converts this Login to JSON.
         * @function toJSON
         * @memberof Login
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Login.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Login;
    })();
    
    $root.RefreshToken = (function() {
    
        /**
         * Properties of a RefreshToken.
         * @exports IRefreshToken
         * @interface IRefreshToken
         * @property {string|null} [refreshToken] RefreshToken refreshToken
         */
    
        /**
         * Constructs a new RefreshToken.
         * @exports RefreshToken
         * @classdesc Represents a RefreshToken.
         * @implements IRefreshToken
         * @constructor
         * @param {IRefreshToken=} [properties] Properties to set
         */
        function RefreshToken(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * RefreshToken refreshToken.
         * @member {string} refreshToken
         * @memberof RefreshToken
         * @instance
         */
        RefreshToken.prototype.refreshToken = "";
    
        /**
         * Creates a new RefreshToken instance using the specified properties.
         * @function create
         * @memberof RefreshToken
         * @static
         * @param {IRefreshToken=} [properties] Properties to set
         * @returns {RefreshToken} RefreshToken instance
         */
        RefreshToken.create = function create(properties) {
            return new RefreshToken(properties);
        };
    
        /**
         * Encodes the specified RefreshToken message. Does not implicitly {@link RefreshToken.verify|verify} messages.
         * @function encode
         * @memberof RefreshToken
         * @static
         * @param {IRefreshToken} message RefreshToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.refreshToken);
            return writer;
        };
    
        /**
         * Encodes the specified RefreshToken message, length delimited. Does not implicitly {@link RefreshToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefreshToken
         * @static
         * @param {IRefreshToken} message RefreshToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a RefreshToken message from the specified reader or buffer.
         * @function decode
         * @memberof RefreshToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefreshToken} RefreshToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefreshToken();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.refreshToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a RefreshToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefreshToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefreshToken} RefreshToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a RefreshToken message.
         * @function verify
         * @memberof RefreshToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RefreshToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                if (!$util.isString(message.refreshToken))
                    return "refreshToken: string expected";
            return null;
        };
    
        /**
         * Creates a RefreshToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefreshToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefreshToken} RefreshToken
         */
        RefreshToken.fromObject = function fromObject(object) {
            if (object instanceof $root.RefreshToken)
                return object;
            var message = new $root.RefreshToken();
            if (object.refreshToken != null)
                message.refreshToken = String(object.refreshToken);
            return message;
        };
    
        /**
         * Creates a plain object from a RefreshToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefreshToken
         * @static
         * @param {RefreshToken} message RefreshToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RefreshToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.refreshToken = "";
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                object.refreshToken = message.refreshToken;
            return object;
        };
    
        /**
         * Converts this RefreshToken to JSON.
         * @function toJSON
         * @memberof RefreshToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RefreshToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return RefreshToken;
    })();
    
    $root.AuthResponse = (function() {
    
        /**
         * Properties of an AuthResponse.
         * @exports IAuthResponse
         * @interface IAuthResponse
         * @property {string|null} [refreshToken] AuthResponse refreshToken
         * @property {string|null} [csrf] AuthResponse csrf
         */
    
        /**
         * Constructs a new AuthResponse.
         * @exports AuthResponse
         * @classdesc Represents an AuthResponse.
         * @implements IAuthResponse
         * @constructor
         * @param {IAuthResponse=} [properties] Properties to set
         */
        function AuthResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * AuthResponse refreshToken.
         * @member {string} refreshToken
         * @memberof AuthResponse
         * @instance
         */
        AuthResponse.prototype.refreshToken = "";
    
        /**
         * AuthResponse csrf.
         * @member {string} csrf
         * @memberof AuthResponse
         * @instance
         */
        AuthResponse.prototype.csrf = "";
    
        /**
         * Creates a new AuthResponse instance using the specified properties.
         * @function create
         * @memberof AuthResponse
         * @static
         * @param {IAuthResponse=} [properties] Properties to set
         * @returns {AuthResponse} AuthResponse instance
         */
        AuthResponse.create = function create(properties) {
            return new AuthResponse(properties);
        };
    
        /**
         * Encodes the specified AuthResponse message. Does not implicitly {@link AuthResponse.verify|verify} messages.
         * @function encode
         * @memberof AuthResponse
         * @static
         * @param {IAuthResponse} message AuthResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.refreshToken);
            if (message.csrf != null && Object.hasOwnProperty.call(message, "csrf"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.csrf);
            return writer;
        };
    
        /**
         * Encodes the specified AuthResponse message, length delimited. Does not implicitly {@link AuthResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof AuthResponse
         * @static
         * @param {IAuthResponse} message AuthResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an AuthResponse message from the specified reader or buffer.
         * @function decode
         * @memberof AuthResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {AuthResponse} AuthResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AuthResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.refreshToken = reader.string();
                    break;
                case 2:
                    message.csrf = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an AuthResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof AuthResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {AuthResponse} AuthResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an AuthResponse message.
         * @function verify
         * @memberof AuthResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                if (!$util.isString(message.refreshToken))
                    return "refreshToken: string expected";
            if (message.csrf != null && message.hasOwnProperty("csrf"))
                if (!$util.isString(message.csrf))
                    return "csrf: string expected";
            return null;
        };
    
        /**
         * Creates an AuthResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof AuthResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {AuthResponse} AuthResponse
         */
        AuthResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.AuthResponse)
                return object;
            var message = new $root.AuthResponse();
            if (object.refreshToken != null)
                message.refreshToken = String(object.refreshToken);
            if (object.csrf != null)
                message.csrf = String(object.csrf);
            return message;
        };
    
        /**
         * Creates a plain object from an AuthResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof AuthResponse
         * @static
         * @param {AuthResponse} message AuthResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.refreshToken = "";
                object.csrf = "";
            }
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                object.refreshToken = message.refreshToken;
            if (message.csrf != null && message.hasOwnProperty("csrf"))
                object.csrf = message.csrf;
            return object;
        };
    
        /**
         * Converts this AuthResponse to JSON.
         * @function toJSON
         * @memberof AuthResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return AuthResponse;
    })();

    return $root;
});
