import { navigate } from 'gatsby'
import MaterialTable, { Query, QueryResult } from 'material-table'
import React, { useRef } from 'react'

import AsyncWorkButton from '@external/rp.ui/components/AsyncWork/AsyncWorkButton'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'
import { tableIcons } from '@external/rp.ui/shared/tableIcons'
import { AuthError, AuthHelper, ProtoClient } from '@external/rp.ui/utils/protoClient'
import { Fab, Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { INosologies, INosologyList, Nosologies, PagingAndSort } from '../../../proto/models'

const url = 'dictionaries/nosologies'

const NosologyDictionary = (props: any) => {
  const tableRef = useRef(null)

  const fetchNosologies = async (query: Query<INosologyList>): Promise<QueryResult<INosologyList>> => {
    const paging = PagingAndSort.create({
      pageIndex: query.page,
      pageSize: query.pageSize,
      sortColumn: 'name',
      sortDirection: query.orderDirection,
      searchQuery: query.search,
    })

    try {
      const nosologies = await ProtoClient.get<INosologies>('dictionaries/nosologies', Nosologies, paging)

      return {
        data: nosologies.items,
        page: query.page,
        totalCount: nosologies.rowCount,
      }
    } catch (error) {
      if (error instanceof AuthError) {
        AuthHelper.redirect(error.fullCallbackUrl)
      }
      throw error
    }
  }

  const navigateToEditWindow = (nosologyId: Uint8Array) => {
    let path = '/nosologies/edit'

    if (nosologyId) {
      path = `${path}/${GuidHelper.toString(nosologyId)}`
    }

    navigate(path)
  }

  const onAddButtonClick = (): void => {
    navigateToEditWindow(null)
  }

  const onEditButtonClick = async (rowData: any): Promise<void> => {
    navigateToEditWindow(rowData.id)
  }

  const onDeleteButtonClick = async (rowData: any): Promise<void> => {
    await ProtoClient.delete('dictionaries/nosologies', GuidHelper.toString(rowData.id))
  }

  const getButtons = () => {
    return (
      <Grid>
        <Fab onClick={(e) => onAddButtonClick()} size="small" color="primary" aria-label="add">
          <AddIcon />
        </Fab>
        <AsyncWorkButton urlSyncStart={url + '/sync'} urlSyncCheck={url + '/sync-progress'} text="Синхронизация" />
      </Grid>
    )
  }

  const getTable = () => {
    return (
      <MaterialTable<INosologyList>
        tableRef={tableRef}
        localization={{
          toolbar: {
            searchTooltip: 'Поиск',
            searchPlaceholder: 'Поиск',
          },
          pagination: {
            labelRowsSelect: 'строк',
          },
        }}
        icons={tableIcons}
        columns={[
          {
            title: '',
            filtering: false,
            sorting: false,
            field: 'edit',
            render: (rowData) => (
              <Fab
                onClick={() => onEditButtonClick(rowData)}
                size="small"
                color="primary"
                aria-label="edit"
                style={{ margin: 0 }}
              >
                <EditIcon />
              </Fab>
            ),
          },
          {
            title: 'Наименование',
            field: 'name',
            sorting: true,
            defaultSort: 'asc',
            render: (rowData) => rowData.name.charAt(0).toUpperCase() + rowData.name.slice(1),
          },
          {
            title: 'Категория',
            field: 'name',
            sorting: true,
            defaultSort: 'asc',
            render: (rowData) => {
              if (rowData.categoryName) {
                return rowData.categoryName?.charAt(0).toUpperCase() + rowData.categoryName?.slice(1)
              }
            },
          },
          {
            title: '',
            filtering: false,
            sorting: false,
            field: 'delete',
            render: (rowData) => (
              <Fab
                onClick={async () => {
                  await onDeleteButtonClick(rowData)
                  tableRef.current && tableRef.current.onQueryChange()
                }}
                size="small"
                color="secondary"
                aria-label="delete"
                style={{ margin: 0 }}
              >
                <DeleteIcon />
              </Fab>
            ),
          },
        ]}
c
        data={(query) => fetchNosologies(query)}
        title="Нозология"
      />
    )
  }

  return (
    <>
      {getButtons()}
      {getTable()}
    </>
  )
}

export default NosologyDictionary
