import { Dispatch } from 'redux'

import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { IAutocompleteSearchResults, AutocompleteSearchResults, EAddressPart } from '../../../proto/models'
import { ISelectState } from '../../types/types'

const addressesUrl = 'dictionaries/pharmacies'

export interface ICountriesEditState {
  countries: ISelectState[]
}

const initialState: ICountriesEditState = {
  countries: [],
}

const COUNTRIES_LOADED = 'COUNTRIES_LOADED'
const countriesLoaded = (countries: IAutocompleteSearchResults) => ({
  type: COUNTRIES_LOADED,
  payload: countries.SearchResults.map((item) => ({
    label: item.name,
    value: item.id,
  })),
})

export const fetchCountries = () => async (dispatch: Dispatch) => {
  const countries = await ProtoClient.get<IAutocompleteSearchResults>(
    addressesUrl + '/search',
    AutocompleteSearchResults,
    {
      AddressPart: EAddressPart.Country,
    }
  )

  dispatch(countriesLoaded(countries))
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case COUNTRIES_LOADED:
      return { ...state, countries: action.payload }
    default:
      return state
  }
}
