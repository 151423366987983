import { IWorkInfo, EWorkStatus, WorkInfo } from '../../proto/models'
import { createReducer } from '@reduxjs/toolkit'
import { AppDispatch, IAppState } from '../rootReducer'
import { WebClient } from '@external/rp.ui/utils/protoClient'
import { checkStatus, CheckStatusDispose } from '@external/rp.ui/utils/workStatusChecker'

export interface IPharmaciesNetworksSyncState {
  workStatus: IWorkInfo
  timer: CheckStatusDispose
}

const initialState: IPharmaciesNetworksSyncState = {
  workStatus: null,
  timer: null,
}

const url = 'dictionaries/pharmaciesNetworks'

export const syncPharmaciesNetworks = () => (dispatch: AppDispatch) => {
  WebClient.request(url + '/sync', 'POST')

  var status: IWorkInfo = {
    status: EWorkStatus.Queue,
    progress: 0,
  }

  dispatch({
    type: CHECK_IMPORT_STATUS,
    payload: status,
  })

  dispatch(checkPharmaciesNetworksSyncStatus())
}

export const checkPharmaciesNetworksSyncStatus = () => (dispatch: AppDispatch) => {
  const id = checkStatus(url + '/sync-progress', (value) => dispatch({ type: CHECK_IMPORT_STATUS, payload: value }))
  dispatch(clearTimer())
  dispatch({ type: SET_TIMER, payload: id })
}

export const clearTimer = () => (dispatch: AppDispatch, getState: () => IAppState) => {
  const timer = getState().pharmaciesNetworks.sync.timer
  if (typeof timer === 'function') {
    timer()
  }
}

export const CHECK_IMPORT_STATUS = 'CHECK_IMPORT_STATUS'
export const SET_TIMER = 'SET_TIMER'

const pharmaciesNetworksSyncReducer = createReducer(initialState, {
  [CHECK_IMPORT_STATUS]: (state, action) => {
    state.workStatus = action.payload
  },
  [SET_TIMER]: (state, action) => {
    state.timer = action.payload
  },
})

export default pharmaciesNetworksSyncReducer
