import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core'
import styles from './RecordActionModalDialog.module.scss'

export interface RecordActionParams {
  button: React.ReactElement
  title: string
  body: string
  agreeString: string
  disagreeString: string
  agreeCallbackArgs: any
  agreeCallback(id: string): void
  disagreeCallback(): void
}

export default function RecordActionDialog(props: RecordActionParams): React.ReactElement<RecordActionParams> {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const agreeButtonClickHandler = () => {
    handleClose()
    props.agreeCallback(props.agreeCallbackArgs)
  }

  const disagreeButtonClickHandler = () => {
    handleClose()
    props.disagreeCallback()
  }

  return (
    <div>
      <div onClick={handleOpen}>{props.button}</div>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={styles.buttonWrapper}>
            <Button variant="contained" color="primary" onClick={agreeButtonClickHandler}>
              {props.agreeString}
            </Button>
          </div>
          <div className={styles.buttonWrapper}>
            <Button variant="contained" color="primary" onClick={disagreeButtonClickHandler}>
              {props.disagreeString}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
