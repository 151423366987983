import React from 'react'
import { connect } from 'react-redux'
import { initialize, reset, submit } from 'redux-form'

import AsyncWork, { IAsyncWorkContext } from '@external/rp.ui/components/AsyncWork/AsyncWork'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { Button } from '@material-ui/core'

import { ILanguageForm, LanguageForm } from '../../../proto/models'
import { clearTimer, syncLanguage } from '../../../reducers/languages/LanguageSyncReducer'
import { IAppState } from '../../../reducers/rootReducer'
import LanguageEditForm, { ILanguageEditWindowContext } from './LanguageEditWindow'
import LanguageTable from './LanguageTable'

const url = 'dictionaries/languages'
const formName = 'languageEditForm'

const LanguageDictionary = (props: any) => {
  React.useEffect(() => {
    return () => {
      props.dispatch(clearTimer())
    }
  }, [])

  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)

  const initialFormValues = {
    id: [],
    internationalName: '',
    nativeName: '',
    code: '',
  }

  const windowParams: ILanguageEditWindowContext = {
    createButtonClickHandler: () => {
      props.dispatch(submit(formName))
    },
    handleClose: () => {
      setOpen(false)
      props.dispatch(initialize(formName, initialFormValues))
    },
    isOpen: open,
  }

  const syncComponent = () => {
    const syncButton = (
      <Button variant="contained" color="primary" onClick={() => props.dispatch(syncLanguage())}>
        Синхронизация
      </Button>
    )
    const asyncWorkProps: IAsyncWorkContext = {
      workStatus: props.syncStatus,
      startWorkElement: syncButton,
    }
    return <AsyncWork {...asyncWorkProps} />
  }

  return (
    <>
      <LanguageEditForm
        {...windowParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            var data = LanguageForm.create({
              id: values.id,
              internationalName: values.internationalName,
              nativeName: values.nativeName,
              code: values.code,
            })

            const task =
              values.id?.length > 0
                ? ProtoClient.post<ILanguageForm>(url, data, LanguageForm, LanguageForm)
                : ProtoClient.put<ILanguageForm>(url, data, LanguageForm, LanguageForm)

            await task
            tableRef.current && tableRef.current.onQueryChange()
            setOpen(false)
            dispatch(initialize(formName, initialFormValues))
          }
        }}
      />
      <LanguageTable
        tableRef={tableRef}
        onDeleteButtonClick={(rowData) => ProtoClient.delete(url, GuidHelper.toString(rowData.id))}
        onEditButtonClick={(rowData) => {
          props.dispatch(
            initialize(formName, {
              id: rowData.id,
              internationalName: rowData.internationalName,
              nativeName: rowData.nativeName,
              code: rowData.code,
            })
          )
          setOpen(true)
        }}
        onAddButtonClick={() => {
          setOpen(true)
        }}
        url={url}
        syncComponent={syncComponent()}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    syncStatus: store.languages.sync.workStatus,
  }
}

export default connect(mapStateToProps, null)(LanguageDictionary)
