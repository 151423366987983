import { AuthError, AuthHelper, AuthProtoClient } from '../../utils/protoClient'
import { AuthResponse, IAuthResponse, ILogin, Login, RefreshToken } from '../proto/models'

export class AuthService {
  public static async Login(login: ILogin): Promise<void> {
    const authResponse = await AuthProtoClient.post<IAuthResponse>(
      'auth/login',
      Login.create(login),
      Login,
      AuthResponse
    )

    AuthHelper.saveCsrfToLocalStorage(authResponse.csrf)
    AuthHelper.saveRefreshTokenToLocalStorage(authResponse.refreshToken)
  }

  public static async refreshToken() {
    const promise = getRefreshPromise()
    await promise
  }

  public static async Logout(): Promise<void> {
    const refreshToken = AuthHelper.getRefreshToken()

    try {
      await AuthProtoClient.post<any>('auth/logout', RefreshToken.create({ refreshToken }), RefreshToken, null)
    } catch (err) {
      throw new AuthError(encodeURIComponent(window.location.pathname), encodeURIComponent(window.location.href))
    } finally {
      AuthHelper.removeCsrfTokenFromLocalStorage()
      AuthHelper.removeRefreshTokenFromLocalStorage()
    }
    throw new AuthError(encodeURIComponent(window.location.pathname), encodeURIComponent(window.location.href))
  }
}

let inSynchronization = false
let refreshPromise: Promise<void>

const refreshSynched = async () => {
  const refreshToken = RefreshToken.create({ refreshToken: AuthHelper.getRefreshToken() })

  try {
    const authResponse = await AuthProtoClient.post<IAuthResponse>(
      'auth/refresh-token',
      refreshToken,
      RefreshToken,
      AuthResponse
    )

    AuthHelper.saveCsrfToLocalStorage(authResponse.csrf)
    AuthHelper.saveRefreshTokenToLocalStorage(authResponse.refreshToken)
  } finally {
    inSynchronization = false
  }
}

const getRefreshPromise = (): Promise<void> => {
  if (!inSynchronization) {
    inSynchronization = true
    refreshPromise = refreshSynched()
  }
  return refreshPromise
}
