import classNames from 'classnames'
import React, { ReactElement } from 'react'
import { WrappedFieldMetaProps } from 'redux-form'

import styles from './CustomAutocompleteInput.module.scss'

interface ICustomAutocompleteInputProps {
  icon?: 'magnifier' | 'arrow-down'
  meta?: WrappedFieldMetaProps
  placeholder?: string
  type: string
  getRootProps?: () => {}
  getInputProps?: () => {}
  groupedOptions?: any[]
  getListboxProps?: () => {}
  getOptionLabel?: (option: any) => string
  getOptionProps?: ({ option, index }: { option: any; index: number }) => {}
  maxHeightResult?: number
  disabled?: boolean
  size?: 'small' | 'large' | 'normal'
  isReserveLabelError?: boolean
}

const CustomAutocompleteInput = (props: ICustomAutocompleteInputProps): ReactElement<ICustomAutocompleteInputProps> => {
  let meta: string
  const { isReserveLabelError = true } = props

  if (props.meta && props.meta.touched && props.meta.error) {
    meta = props.meta.error
  }

  if (props.meta && props.meta.touched && props.meta.warning) {
    meta = props.meta.warning
  }

  const labelErrorClassName = classNames(styles.label, {
    [styles.labelError]: props.meta && props.meta.touched && (props.meta.error || props.meta.warning),
  })

  const size = props.size ?? 'large'

  const inputClassName = classNames(styles.customInput, {
    [styles.customInputError]: props.meta && props.meta.touched && (props.meta.error || props.meta.warning),
    [styles.customInputSmall]: size === 'small',
    [styles.customInputLarge]: size === 'large',
    [styles.customInputNormal]: size === 'normal',
  })

  return (
    <div className={styles.blockCustomInput} {...props.getRootProps()}>
      <span className={inputClassName}>
        <span
          className={classNames(styles.icon, styles.iconArrownDown, {
            [styles.iconMagnifier]: props.icon === 'magnifier',
          })}
        ></span>
        <input
          {...props.getInputProps()}
          placeholder={props.placeholder}
          className={styles.customInputInput}
          type={props.type}
          disabled={props.disabled}
        />
      </span>
      {isReserveLabelError && <span className={labelErrorClassName}>{meta}&nbsp;</span>}
      {props.groupedOptions.length > 0 ? (
        <ul className={styles.list} {...props.getListboxProps()} style={{ maxHeight: props.maxHeightResult }}>
          {props.groupedOptions.map((option, index) => {
            return (
              <li key={index} className={styles.listItem} {...props.getOptionProps({ option, index })}>
                <span className={styles.listText}>{props.getOptionLabel(option)}</span>
              </li>
            )
          })}
        </ul>
      ) : null}
    </div>
  )
}

export default CustomAutocompleteInput
