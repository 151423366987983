import React, { Component, SyntheticEvent } from 'react'
import { connect } from 'react-redux'

import AsyncWork, { IAsyncWorkContext } from '@external/rp.ui/components/AsyncWork/AsyncWork'
import { Button, Grid, TablePagination } from '@material-ui/core'

import {
  checkImportStatus,
  clearImportTimer,
  IDrugsImportState,
  importDrugs,
} from '../../../../reducers/drugs/drugsImportReducer'
import { checkSyncStatus, clearTimer, IDrugsSyncState, syncDrugs } from '../../../../reducers/drugs/drugsSyncReducer'
import { IAppState, IDrugsState } from '../../../../reducers/rootReducer'
import styles from './DrugsDictionary.module.scss'
import { DrugListDtos, IDrugListDto, IDrugListDtos, PagingAndSort } from 'src/proto/models'
import MaterialTable, { Column, MTableToolbar, Query, QueryResult } from 'material-table'
import EditButton from 'src/components/buttons/EditButton'
import { IListState } from 'src/data/state'
import { tableIcons } from '@external/rp.ui/shared/tableIcons'
import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'
import { navigate } from 'gatsby-link'

interface IDrugsContext {
  import: IDrugsImportState
  sync: IDrugsSyncState
}

class DrugsDictionary extends Component<IDrugsContext> {
  private tableRef = React.createRef()
  constructor(props: IDrugsContext) {
    super(props)
  }

  componentDidMount() {
    this.props.dispatch(checkImportStatus())
    this.props.dispatch(checkSyncStatus())
  }

  componentWillUnmount() {
    this.props.dispatch(clearImportTimer())
    this.props.dispatch(clearTimer())
  }

  private fetchDrugs = async (query: Query<IDrugListDto>): Promise<QueryResult<IDrugListDto>> => {
    const pagingAndSort = PagingAndSort.create({
      pageIndex: query.page,
      pageSize: query.pageSize,
      sortColumn: 'drugName',
      sortDirection: query.orderDirection,
      searchQuery: query.search,
    })

    const drugs = await ProtoClient.get<IDrugListDtos>('dictionaries/drugs/list', DrugListDtos, pagingAndSort)

    return {
      data: drugs.items,
      page: query.page,
      totalCount: drugs.rowCount,
    }
  }

  private onImportButtonClick = (event: SyntheticEvent) => {
    this.props.dispatch(importDrugs())
  }

  private onSyncBtnClick = () => {
    this.props.dispatch(syncDrugs())
  }

  private importComponent = () => {
    const importInput = (
      <>
        <Button className={styles.button} variant="contained" color="primary" onClick={this.onImportButtonClick}>
          {'Импорт из Pharm'}
        </Button>
      </>
    )
    const asyncWorkProps: IAsyncWorkContext = {
      workStatus: this.props.import.workStatus,
      startWorkElement: importInput,
    }
    return <AsyncWork {...asyncWorkProps} />
  }

  private syncComponent = () => {
    const syncInput = (
      <>
        <Button className={styles.button} variant="contained" color="primary" onClick={this.onSyncBtnClick}>
          {'Синхронизация'}
        </Button>
      </>
    )
    const asyncWorkProps: IAsyncWorkContext = {
      workStatus: this.props.sync.workStatus,
      startWorkElement: syncInput,
    }
    return <AsyncWork {...asyncWorkProps} />
  }

  private addButton = () => {
    return (
    <Button variant="contained" color="primary" onClick={() => navigate('/drugs/edit')}>
      Добавить
    </Button>)
  }

  private columns: Column<IDrugListDto>[] = [
    {
      title: '',
      field: 'edit',
      width: '200px',
      render: (rowData: { drugNameId: Uint8Array }) => <EditButton onClick={async () => {
        await this.onEditButtonClick(rowData.drugNameId)
      }} />,
    },
    {
      title: 'Наименование',
      field: 'drugName',
    },
  ]

  private navigateToEditWindow = (drugNameId: Uint8Array) => {
    let path = '/drugs/edit'

    if (drugNameId) {
      path = `${path}/${GuidHelper.toString(drugNameId)}`
    }

    navigate(path)
  }

  private onEditButtonClick = async (drugNameId: Uint8Array): Promise<void> => {
    this.navigateToEditWindow(drugNameId)
  }

  private tableComponent = () => {
    return <MaterialTable<IDrugListDto>
        tableRef={this.tableRef}
        localization={{
          toolbar: {
            searchTooltip: 'Поиск',
            searchPlaceholder: 'Поиск',
          },
          pagination: {
            labelRowsSelect: 'строк',
          },
        }}
        title="Препараты"
        icons={tableIcons}
        columns={this.columns}
        options={{
          search: true,
          emptyRowsWhenPaging: false,
          showTextRowsSelected: false,
          debounceInterval: 2000,
          searchFieldAlignment: 'right',
          draggable: false,
          pageSize: 25,
          pageSizeOptions: [25, 50, 100],
        }}
        data={(query) => this.fetchDrugs(query)}
      />
  }

  render() {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item>{this.addButton()}</Grid>
          <Grid item>{this.importComponent()}</Grid>
          <Grid item>{this.syncComponent()}</Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item className={styles.drugTable}>{this.tableComponent()}</Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = (store: IAppState): IDrugsContext => {
  return {
    import: store.drugs.import,
    sync: store.drugs.sync,
  }
}

export default connect(mapStateToProps, null)(DrugsDictionary)
