export class AuthError extends Error {
  callbackUrl: string
  fullCallbackUrl: string
  constructor(url?: string, fullUrl?: string) {
    super('Не удалось авторизоваться')
    this.name = 'AuthError'
    this.callbackUrl = url
    this.fullCallbackUrl = fullUrl
  }
}
