import { navigate } from 'gatsby'
import React from 'react'
import { Field, Form, initialize, InjectedFormProps, reduxForm } from 'redux-form'

import { TextField } from '@external/rp.ui/components/MaterialReduxForm'
import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { Box, Button, Card, CardActions, CardContent, CardHeader, FormControl, FormLabel } from '@material-ui/core'

import { CategoryForm, CategoryValidation, ICategoryForm, ICategoryValidation } from '../../../proto/models'

export interface ICategoryEditWindowContext {
  path: string
}

const required = (value: any) => (value ? undefined : 'Не заполнено обязательное поле')

const EditForm = (
  props: ICategoryEditWindowContext & InjectedFormProps<ICategoryForm, ICategoryEditWindowContext, string>
) => {
  const { handleSubmit, submitting } = props

  return (
    <Card>
      <CardHeader title="Категории" />
      <Form onSubmit={handleSubmit}>
        <CardContent>
          <Box p={1} width="50%">
            <FormControl fullWidth>
              <Box p={1}>
                <FormLabel component="legend">Наименование</FormLabel>
                <Field
                  placeholder={'Введите наименование'}
                  name={'name'}
                  component={TextField}
                  validate={required}
                  maxLength={255}
                  fullWidth
                />
              </Box>
              <Box p={1}>
                <FormLabel component="legend">Описание категории</FormLabel>
                <Field
                  placeholder={'Введите Описание категории'}
                  name={'description'}
                  component={TextField}
                  maxLength={255}
                  multiline
                  rowsMax={4}
                  fullWidth
                />
              </Box>
            </FormControl>
          </Box>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" disabled={submitting} type="submit">
            Сохранить
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              navigate('/categories')
            }}
          >
            Назад
          </Button>
        </CardActions>
      </Form>
    </Card>
  )
}

let CategoryEditForm = reduxForm<ICategoryForm, ICategoryEditWindowContext>({
  form: 'categoryEditForm',
})(EditForm)

const url = 'dictionaries/categories'

export default (windowParams: ICategoryEditWindowContext) => (
  <CategoryEditForm
    {...windowParams}
    asyncValidate={async (values, dispatch, props) => {
      var data = CategoryForm.create(values)
      var result = await ProtoClient.post<ICategoryValidation>(
        url + '/validate',
        data,
        CategoryForm,
        CategoryValidation
      )
      if (result.name) {
        throw result
      }
    }}
    onSubmit={async (values, dispatch, props) => {
      if (props.valid) {
        var data = CategoryForm.create({
          id: values.id,
          name: values.name,
          description: values.description,
        })

        const task =
          values.id?.length > 0
            ? ProtoClient.post<ICategoryForm>(url, data, CategoryForm, CategoryForm)
            : ProtoClient.put<ICategoryForm>(url, data, CategoryForm, CategoryForm)

        await task
        navigate('/categories')
      }
    }}
  />
)
