import { Dispatch } from 'redux'

import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { AutocompleteSearchResults, EAddressPart, IAutocompleteSearchResults } from '../../../proto/models'
import { ISelectState } from '../../types/types'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'

const addressesUrl = 'dictionaries/pharmacies'

export interface IDistrictEditState {
  districts: ISelectState[]
}

const initialState: IDistrictEditState = {
  districts: [],
}

const DISTRICTS_LOADED = 'DISTRICTS_LOADED'
export const districtsLoaded = (districts: IAutocompleteSearchResults) => ({
  type: DISTRICTS_LOADED,
  payload: districts.SearchResults.map((item) => ({ label: item.name, value: item.id })),
})

export const fetchDistricts =
  (country: ISelectState, region: ISelectState, settlement: ISelectState) => async (dispatch: Dispatch) => {
    const districts = await ProtoClient.get<IAutocompleteSearchResults>(
      addressesUrl + '/search',
      AutocompleteSearchResults,
      {
        AddressPart: EAddressPart.District,
        CountryId: GuidHelper.toString(country.value),
        RegionId: GuidHelper.toString(region.value),
        SettlementId: GuidHelper.toString(settlement.value),
      }
    )

    return dispatch(districtsLoaded(districts))
  }

export default (state = initialState, action: any) => {
  switch (action.type) {
    case DISTRICTS_LOADED:
      return {
        ...state,
        loadingDistricts: false,
        districts: action.payload,
      }
    default:
      return state
  }
}
