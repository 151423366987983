import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'

import { Link, withPrefix } from 'gatsby'

import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles'
import { Router } from '@reach/router'
import Navigation from '../Navigation'
import PharmaciesDictionary from '../dictionaries/pharmacies/PharmaciesDictionary'
import PharmaciesEditWindow from '../dictionaries/pharmacies/PharmaciesEditWindow'
import AddressesDictionary from '../dictionaries/addresses/AddressesDictionary'
import PharmaciesNetworksDictionary from '../dictionaries/pharmaciesNetworks/PharmaciesNetworksDictionary/PharmaciesNetworksDictionary'
import PharmaciesNetworkEditWindow from '../dictionaries/pharmaciesNetworks/PharmaciesNetworksEditWindow/PharmaciesNetworksEditWindow'
import DrugsDictionary from '../dictionaries/drugs/DrugsDictionary/DrugsDictionary'
import CurrencyDictionary from '../dictionaries/currencies/CurrencyDictionary'
import CountryCallingCodeDictionary from '../dictionaries/countryCallingCodes/CountryCallingCodeDictionary'
import LanguageDictionary from '../dictionaries/languages/LanguageDictionary'
import CategoryDictionary from '../dictionaries/categories/CategoryDictionary'
import CategoryEditWindow from '../dictionaries/categories/CategoryEditWindow'
import { NosologyDictionary, NosologyEditWindow } from '../dictionaries/nosologies'
import { AuthError, AuthHelper } from '@external/rp.ui/utils/protoClient'
import DrugsEditWindow from '../dictionaries/drugs/DrugsEditWindow'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
)

const getNavigateLinkItem = function (label: string, path: string, key: string): any {
  return (
    <div key={key}>
      <Link to={path}>{label}</Link>
    </div>
  )
}

export default (props) => {
  const classes = useStyles()
  const theme = useTheme()

  React.useEffect(() => {
    window.onunhandledrejection = (ev: PromiseRejectionEvent) => {
      if (ev.reason instanceof AuthError) {
        ev.preventDefault()
        AuthHelper.redirect(ev.reason.fullCallbackUrl)
        return
      }
    }

    return () => {
      window.onrejectionhandled = null
      window.onunhandledrejection = null
    }
  })

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <Navigation />
    </div>
  )

  const { container } = props

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Административная панель
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Router>
          <AddressesDictionary path={withPrefix('addresses')} />
          <DrugsDictionary path={withPrefix('drugs')} />
          <DrugsEditWindow path={withPrefix('drugs/edit')} />
          <DrugsEditWindow path={withPrefix('drugs/edit/:drugNameId')} />
          <PharmaciesDictionary path={withPrefix('pharmacies')} />
          <PharmaciesEditWindow path={withPrefix('pharmacies/edit')} />
          <PharmaciesEditWindow path={withPrefix('pharmacies/edit/:id')} />
          <PharmaciesNetworksDictionary path={withPrefix('pharmaciesNetworks')} />
          <PharmaciesNetworkEditWindow path={withPrefix('pharmaciesNetwork/edit')} />
          <PharmaciesNetworkEditWindow path={withPrefix('pharmaciesNetwork/edit/:id')} />
          <CurrencyDictionary path={withPrefix('currencies')} />
          <CountryCallingCodeDictionary path={withPrefix('telephone-codes')} />
          <LanguageDictionary path={withPrefix('languages')} />
          <CategoryDictionary path={withPrefix('categories')} />
          <CategoryEditWindow path={withPrefix('categories/edit')} />
          <NosologyDictionary path={withPrefix('nosologies')} />
          <NosologyEditWindow path={withPrefix('nosologies/edit')} />
          <NosologyEditWindow path={withPrefix('nosologies/edit/:id')} />
        </Router>
      </main>
    </div>
  )
}
