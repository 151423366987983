import { Dispatch } from 'redux'

import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from '@external/rp.ui/utils/protoClient'

import { IEditState } from '../../data/state'
import { IPharmacy, Pharmacy } from '../../proto/models'
import { AppDispatch, IAppState } from '../rootReducer'
import { createAction } from '@reduxjs/toolkit'
import { fetchRegions } from './editForm/regionReducer'
import { fetchSettlements } from './editForm/settlementReducer'
import { fetchDistricts } from './editForm/districtReducer'
import { fetchStreets } from './editForm/streetReducer'
import { fetchHouses } from './editForm/houseReducer'

const pharmaciesUrl = 'dictionaries/pharmacies'

const initialState: IEditState<IPharmacy> = {
  loading: true,
  data: null,
}

const LOADING_PHARMACY = 'LOADING_PHARMACY'
const loadingPharmacy = () => ({
  type: LOADING_PHARMACY,
})

export const PHARMACY_LOADED = 'PHARMACY_LOADED'
const pharmacyLoaded = (pharmacy: IPharmacy) => ({
  type: PHARMACY_LOADED,
  payload: pharmacy,
})

export const fetchPharmacy = (pharmacyId: string) => async (dispatch: AppDispatch) => {
  dispatch(loadingPharmacy())

  const path = `${pharmaciesUrl}/edit`

  let pharmacy: IPharmacy = null

  if (pharmacyId) {
    pharmacy = await ProtoClient.get<IPharmacy>(path, Pharmacy, {
      id: pharmacyId,
    })

    const country = { value: pharmacy.countryId, label: pharmacy.countryName }

    dispatch(fetchRegions(country))

    if (pharmacy.regionId) {
      const region = { value: pharmacy.regionId, label: pharmacy.regionName }

      dispatch(fetchSettlements(country, region))

      const street = pharmacy.streetId ? { value: pharmacy.streetId, label: pharmacy.streetName } : null
      const settlement = pharmacy.settlementId ? { value: pharmacy.settlementId, label: pharmacy.settlementName } : null
      dispatch(fetchStreets(country, region, settlement))
      dispatch(fetchHouses(country, region, settlement, street))
      if (settlement) {
        dispatch(fetchDistricts(country, region, settlement))
      }
    }
  }

  return dispatch(pharmacyLoaded(pharmacy))
}

const CREATING_PHARMACY = 'CREATING_PHARMACY'
const creatingPharmacy = () => ({
  type: CREATING_PHARMACY,
})

const PHARMACY_CREATED = 'PHARMACY_CREATED'
const createdPharmacy = () => ({
  type: PHARMACY_CREATED,
})

const formToPharmacy = (editFormValues: { [fieldName: string]: any }) => {
  const pharmaciesNetwork = {
    pharmaciesNetworkId: editFormValues.pharmaciesNetwork?.value,
    pharmaciesNetwork: editFormValues.pharmaciesNetwork?.label,
  }

  const country = {
    countryId: editFormValues.country.value,
    countryName: editFormValues.country.label,
  }

  const region = {
    regionId: editFormValues.region.value,
    regionName: editFormValues.region.label,
  }

  const settlement = {
    settlementId: editFormValues.settlement?.value,
    settlementName: editFormValues.settlement?.label,
  }

  const district = {
    districtId: editFormValues.district?.value,
    districtName: editFormValues.district?.label,
  }

  const street = editFormValues.street
    ? {
        streetId: editFormValues.street?.value,
        streetName: editFormValues.street?.label,
      }
    : null

  const house = {
    houseId: editFormValues.house.value,
    houseNumber: editFormValues.house.label,
  }

  return Pharmacy.create({
    name: editFormValues.name,
    ...pharmaciesNetwork,
    ...country,
    ...region,
    ...settlement,
    ...district,
    ...street,
    ...house,
  })
}

export const onCreatePharmacy = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  dispatch(creatingPharmacy())

  const state = getState()
  const pharmacy = formToPharmacy(state.form.pharmacyEditForm.values)

  await ProtoClient.put<IPharmacy>(pharmaciesUrl, pharmacy, Pharmacy, Pharmacy)
  return dispatch(createdPharmacy())
}

const UPDATING_PHARMACY = 'UPDATING_PHARMACY'
const updatingPharmacy = () => ({
  type: UPDATING_PHARMACY,
})

const PHARMACY_UPDATED = 'PHARMACY_UPDATED'
const updatedPharmacy = () => ({
  type: PHARMACY_UPDATED,
})

export const onUpdatePharmacy = (id: string) => async (dispatch: Dispatch, getState: () => IAppState) => {
  dispatch(updatingPharmacy())

  const state = getState()
  const pharmacy = formToPharmacy(state.form.pharmacyEditForm.values)

  pharmacy.id = GuidHelper.parse(id)

  await ProtoClient.post(pharmaciesUrl, pharmacy, Pharmacy)

  return dispatch(updatedPharmacy())
}

export const initPharmacyEdit = createAction('PHARMACY_EDIT_INIT')

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING_PHARMACY:
      return { ...state, loading: true, data: null }
    case CREATING_PHARMACY:
    case UPDATING_PHARMACY:
      return { ...state, loading: true, data: null }
    case PHARMACY_LOADED:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case PHARMACY_CREATED:
    case PHARMACY_UPDATED:
      return { ...state, loading: false, data: null }
    case initPharmacyEdit.toString():
      return initialState
    default:
      return state
  }
}
