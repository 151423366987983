import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { IPharmaciesNetwork, PharmaciesNetwork } from '../../proto/models'
import { Dispatch } from 'redux'
import { IAppState } from '../rootReducer'
import { IEditState } from '../../data/state'
import { createAction, createReducer } from '@reduxjs/toolkit'

const pharmaciesNetworksUrl = 'dictionaries/pharmaciesNetworks'

const initialState: IEditState<IPharmaciesNetwork> = {
  loading: false,
  data: null,
}

const RESET_PHAMACIES_NETWORK = 'RESET_PHAMACIES_NETWORK'
export const resetPhamaciesNetwork = createAction(RESET_PHAMACIES_NETWORK)

const LOADING_PHARMACIES_NETWORK = 'LOADING_PHARMACIES_NETWORK'
const loadingPharmaciesNetwork = createAction(LOADING_PHARMACIES_NETWORK)

export const PHARMACIES_NETWORK_LOADED = 'PHARMACIES_NETWORK_LOADED'
const pharmaciesNetworkLoaded = createAction<IPharmaciesNetwork>(PHARMACIES_NETWORK_LOADED)

const CREATING_PHARMACIES_NETWORK = 'CREATING_PHARMACIES_NETWORK'
const creatingPharmaciesNetwork = createAction(CREATING_PHARMACIES_NETWORK)

const PHARMACIES_NETWORK_CREATED = 'PHARMACIES_NETWORK_CREATED'
const createdPharmaciesNetwork = createAction(PHARMACIES_NETWORK_CREATED)

const UPDATING_PHARMACIES_NETWORK = 'UPDATING_PHARMACIES_NETWORK'
const updatingPharmaciesNetwork = createAction(UPDATING_PHARMACIES_NETWORK)

const PHARMACIES_NETWORK_UPDATED = 'PHARMACIES_NETWORK_UPDATED'
const updatedPharmaciesNetwork = createAction(PHARMACIES_NETWORK_UPDATED)

export const fetchPharmaciesNetwork = (pharmaciesNetworkId: string) => async (dispatch: Dispatch) => {
  dispatch(loadingPharmaciesNetwork())
  const path = `${pharmaciesNetworksUrl}/edit`

  let pharmaciesNetwork: IPharmaciesNetwork = null

  if (pharmaciesNetworkId) {
    pharmaciesNetwork = await ProtoClient.get<IPharmaciesNetwork>(path, PharmaciesNetwork, { id: pharmaciesNetworkId })
  }

  dispatch(pharmaciesNetworkLoaded(pharmaciesNetwork))
}

export const onCreatePharmaciesNetwork = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  dispatch(creatingPharmaciesNetwork())
  const state = getState()
  validate(state)

  const pharmaciesNetwork = formToPharmaciesNetwork(state.form.pharmaciesNetworkEditForm.values)
  await ProtoClient.put<IPharmaciesNetwork>(
    pharmaciesNetworksUrl,
    pharmaciesNetwork,
    PharmaciesNetwork,
    PharmaciesNetwork
  )

  dispatch(createdPharmaciesNetwork())
}

export const onUpdatePharmaciesNetwork = (id: string) => async (dispatch: Dispatch, getState: () => IAppState) => {
  dispatch(updatingPharmaciesNetwork())

  const state = getState()

  validate(state)

  const pharmaciesNetwork = formToPharmaciesNetwork(state.form.pharmaciesNetworkEditForm.values)
  await ProtoClient.post(pharmaciesNetworksUrl, pharmaciesNetwork, PharmaciesNetwork, PharmaciesNetwork)

  dispatch(updatedPharmaciesNetwork())
}

const validate = (state: IAppState) => {
  if (!state.form.pharmaciesNetworkEditForm || !state.form.pharmaciesNetworkEditForm.values) {
    throw new Error('Не заполнены поля формы')
  }
}

const formToPharmaciesNetwork = (editFormValues: any) => {
  return PharmaciesNetwork.create({
    id: editFormValues.id,
    name: editFormValues.name,
  })
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(loadingPharmaciesNetwork, (state) => {
      state.data = null
      state.loading = true
    })
    .addCase(creatingPharmaciesNetwork, (state) => {
      state.loading = true
    })
    .addCase(updatingPharmaciesNetwork, (state) => {
      state.loading = true
    })
    .addCase(pharmaciesNetworkLoaded, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    .addCase(createdPharmaciesNetwork, (state) => {
      state.loading = false
    })
    .addCase(updatedPharmaciesNetwork, (state) => {
      state.loading = false
    })
    .addCase(resetPhamaciesNetwork, () => initialState)
})
