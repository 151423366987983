import { Dispatch } from 'redux'

import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { AutocompleteSearchResults, EAddressPart, IAutocompleteSearchResults } from '../../../proto/models'
import { ISelectState } from '../../types/types'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'

const addressesUrl = 'dictionaries/pharmacies'

export interface IStreetsEditState {
  streets: ISelectState[]
}

const initialState: IStreetsEditState = {
  streets: [],
}

const STREETS_LOADED = 'STREETS_LOADED'
export const streetsLoaded = (streets: IAutocompleteSearchResults) => ({
  type: STREETS_LOADED,
  payload: streets.SearchResults.map((item) => ({ label: item.name, value: item.id })),
})

export const fetchStreets =
  (country: ISelectState, region: ISelectState, settlement?: ISelectState) => async (dispatch: Dispatch) => {
    const streets = await ProtoClient.get<IAutocompleteSearchResults>(
      addressesUrl + '/search',
      AutocompleteSearchResults,
      {
        AddressPart: EAddressPart.Street,
        CountryId: GuidHelper.toString(country.value),
        RegionId: GuidHelper.toString(region.value),
        SettlementId: settlement && settlement.value ? GuidHelper.toString(settlement.value) : null,
      }
    )

    return dispatch(streetsLoaded(streets))
  }

export default (state = initialState, action: any) => {
  switch (action.type) {
    case STREETS_LOADED:
      return { ...state, loadingStreets: false, streets: action.payload }
    default:
      return state
  }
}
