import { Localization } from 'material-table'

export class LocalizationHelper {
  public static GetLocalization(): Localization {
    return {
      pagination: {
        labelRowsSelect: 'строк',
        labelDisplayedRows: '{from}-{to} из {count}',
        previousTooltip: 'Предыдущая страница',
        firstTooltip: 'Первая страница',
        nextTooltip: 'Следующая страница',
        lastTooltip: 'Последняя страница',
      },
    }
  }
}
