export class AuthHelper {
  private constructor() {}

  private static csrfStorageKey = 'csrf'
  private static refreshTokenStorageKey = 'refreshToken'

  public static redirect(callback?: string): void {
    if (typeof window !== 'undefined') {
      const returnUrl = callback ?? window.location.href
      window.open(`${window.location.origin}/auth/login/${encodeURIComponent(returnUrl)}`, '_self')
    }
  }

  public static isAuthFailed(response: Response): boolean {
    return AuthHelper.isNotAuthenticated(response) || AuthHelper.isNotAuthorize(response)
  }

  public static isNotAuthenticated(response: Response): boolean {
    return response.status === 401
  }

  public static isNotAuthorize(response: Response): boolean {
    return response.status === 403
  }

  public static saveCsrfToLocalStorage(csrf: string): void {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(AuthHelper.csrfStorageKey, JSON.stringify(csrf))

      return
    }

    throw Error('Не удалось записать csrf-токен в хранилище')
  }

  public static getCsrfToken(): string {
    return JSON.parse(window.localStorage.getItem(AuthHelper.csrfStorageKey))
  }

  public static setCsrfToHeader(options: any): any {
    if (typeof window !== 'undefined') {
      const csrfToken = AuthHelper.getCsrfToken()
      options.headers = { ...options.headers, 'X-Csrf-Token': csrfToken }

      return options
    }
  }

  public static saveRefreshTokenToLocalStorage(refreshToken: string): void {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(AuthHelper.refreshTokenStorageKey, refreshToken)

      return
    }

    throw Error('Не удалось записать refresh-токен в хранилище')
  }

  public static removeRefreshTokenFromLocalStorage(): void {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(AuthHelper.refreshTokenStorageKey)
    }
  }

  public static removeCsrfTokenFromLocalStorage(): void {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(AuthHelper.csrfStorageKey)
    }
  }

  public static getRefreshToken(): string {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem(AuthHelper.refreshTokenStorageKey)
    }
  }
}
