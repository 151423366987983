import React from 'react'
import { Fab } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import styles from './EditButton.module.scss'

interface IEditButtonContext {
  onClick(data: any): void
}

const EditButton = (props: IEditButtonContext) => {
  return (
    <Fab onClick={props.onClick} size="small" color="primary" aria-label="edit" className={styles.editButton}>
      <EditIcon />
    </Fab>
  )
}

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default EditButton
