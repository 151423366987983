import { combineReducers } from 'redux'
import drugReducer from './drugReducer'
import drugsImportReducer from './drugsImportReducer'
import drugsSyncReducer from './drugsSyncReducer'

export const rootDrugsReducer = combineReducers({
  import: drugsImportReducer,
  sync: drugsSyncReducer,
  edit: drugReducer
})
