import { Dispatch } from 'redux'

import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { AutocompleteSearchResults, EAddressPart, IAutocompleteSearchResults } from '../../../proto/models'
import { ISelectState } from '../../types/types'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'

const addressesUrl = 'dictionaries/pharmacies'

export interface IRegionsEditState {
  regions: ISelectState[]
}

const initialState: IRegionsEditState = {
  regions: [],
}

const REGIONS_LOADED = 'REGIONS_LOADED'
const regionsLoaded = (regions: IAutocompleteSearchResults) => ({
  type: REGIONS_LOADED,
  payload: regions.SearchResults.map((item) => ({ label: item.name, value: item.id })),
})

export const fetchRegions = (country: ISelectState) => async (dispatch: Dispatch) => {
  const regions = await ProtoClient.get<IAutocompleteSearchResults>(
    addressesUrl + '/search',
    AutocompleteSearchResults,
    {
      AddressPart: EAddressPart.Region,
      CountryId: GuidHelper.toString(country.value),
    }
  )

  dispatch(regionsLoaded(regions))
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case REGIONS_LOADED:
      return { ...state, loadingRegions: false, regions: action.payload }
    default:
      return state
  }
}
