import { navigate } from 'gatsby'
import React from 'react'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'

import AsyncWork, { IAsyncWorkContext } from '@external/rp.ui/components/AsyncWork/AsyncWork'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { Button } from '@material-ui/core'

import { checkCategorySyncStatus, clearTimer, syncCategory } from '../../../reducers/categories/categorySyncReducer'
import { IAppState } from '../../../reducers/rootReducer'
import CategoryTable from './CategoryTable'

const url = 'dictionaries/categories'

const CategoryDictionary = (props: any) => {
  React.useEffect(() => {
    props.dispatch(checkCategorySyncStatus())
    return () => {
      props.dispatch(clearTimer())
    }
  }, [])

  const tableRef = React.createRef<any>()

  const syncComponent = () => {
    const syncButton = (
      <Button variant="contained" color="primary" onClick={() => props.dispatch(syncCategory())}>
        Синхронизация
      </Button>
    )
    const asyncWorkProps: IAsyncWorkContext = {
      workStatus: props.syncStatus,
      startWorkElement: syncButton,
    }
    return <AsyncWork {...asyncWorkProps} />
  }

  return (
    <CategoryTable
      tableRef={tableRef}
      onDeleteButtonClick={(rowData) => ProtoClient.delete(url, GuidHelper.toString(rowData.id))}
      onEditButtonClick={(rowData) => {
        props.dispatch(initialize('categoryEditForm', rowData))
        navigate('/categories/edit')
      }}
      onAddButtonClick={() => {
        navigate('/categories/edit')
      }}
      syncComponent={syncComponent()}
      url={url}
    />
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    syncStatus: store.categories.sync.workStatus,
  }
}

export default connect(mapStateToProps, null)(CategoryDictionary)
