import { Button } from '@material-ui/core'
import React, { SyntheticEvent } from 'react'
import { connect } from 'react-redux'

import { importAddresses, sync } from '../../../../reducers/addressesReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import UploadFile from '../../../UploadFile'
import styles from './AddressesDictionary.module.scss'

type AddressesStateProps = {
  importing: boolean
}

type AddressesDispatchProps = {
  sync: () => void
  import: (event: SyntheticEvent) => void
}

type OwnProps = {
  path: string
}

type Props = AddressesStateProps & AddressesDispatchProps & OwnProps

const AddressesDictionary = (props: Props) => (
  <div>
    <div className={styles.toolbarButtonsWrapper}>
      <div className={styles.buttonWrapper}>
        <UploadFile accept="*.json" onChange={props.import} />
      </div>
      <div className={styles.buttonWrapper}>
        <Button variant="contained" color="primary" onClick={props.sync}>
          {'Синхронизация'}
        </Button>
      </div>
    </div>
  </div>
)

const mapDispatchToProps = (dispatch: AppDispatch): AddressesDispatchProps => ({
  sync: () => {
    dispatch(sync())
  },
  import: (event) => {
    dispatch(importAddresses(event))
  },
})

const mapStateToProps = (store: IAppState) => ({
  importing: store.addresses.importing,
})

export default connect<AddressesStateProps, AddressesDispatchProps, OwnProps, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(AddressesDictionary)
