/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.WorkInfo = (function() {
    
        /**
         * Properties of a WorkInfo.
         * @exports IWorkInfo
         * @interface IWorkInfo
         * @property {number|null} [progress] WorkInfo progress
         * @property {string|null} [description] WorkInfo description
         * @property {number|Long|null} [finishedTime] WorkInfo finishedTime
         * @property {EWorkStatus|null} [status] WorkInfo status
         * @property {string|null} [result] WorkInfo result
         */
    
        /**
         * Constructs a new WorkInfo.
         * @exports WorkInfo
         * @classdesc Represents a WorkInfo.
         * @implements IWorkInfo
         * @constructor
         * @param {IWorkInfo=} [properties] Properties to set
         */
        function WorkInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * WorkInfo progress.
         * @member {number} progress
         * @memberof WorkInfo
         * @instance
         */
        WorkInfo.prototype.progress = 0;
    
        /**
         * WorkInfo description.
         * @member {string} description
         * @memberof WorkInfo
         * @instance
         */
        WorkInfo.prototype.description = "";
    
        /**
         * WorkInfo finishedTime.
         * @member {number|Long} finishedTime
         * @memberof WorkInfo
         * @instance
         */
        WorkInfo.prototype.finishedTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
        /**
         * WorkInfo status.
         * @member {EWorkStatus} status
         * @memberof WorkInfo
         * @instance
         */
        WorkInfo.prototype.status = 0;
    
        /**
         * WorkInfo result.
         * @member {string} result
         * @memberof WorkInfo
         * @instance
         */
        WorkInfo.prototype.result = "";
    
        /**
         * Creates a new WorkInfo instance using the specified properties.
         * @function create
         * @memberof WorkInfo
         * @static
         * @param {IWorkInfo=} [properties] Properties to set
         * @returns {WorkInfo} WorkInfo instance
         */
        WorkInfo.create = function create(properties) {
            return new WorkInfo(properties);
        };
    
        /**
         * Encodes the specified WorkInfo message. Does not implicitly {@link WorkInfo.verify|verify} messages.
         * @function encode
         * @memberof WorkInfo
         * @static
         * @param {IWorkInfo} message WorkInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.progress);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            if (message.finishedTime != null && Object.hasOwnProperty.call(message, "finishedTime"))
                writer.uint32(/* id 3, wireType 1 =*/25).sfixed64(message.finishedTime);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.result);
            return writer;
        };
    
        /**
         * Encodes the specified WorkInfo message, length delimited. Does not implicitly {@link WorkInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof WorkInfo
         * @static
         * @param {IWorkInfo} message WorkInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a WorkInfo message from the specified reader or buffer.
         * @function decode
         * @memberof WorkInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {WorkInfo} WorkInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.WorkInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.progress = reader.int32();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.finishedTime = reader.sfixed64();
                    break;
                case 4:
                    message.status = reader.int32();
                    break;
                case 5:
                    message.result = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a WorkInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof WorkInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {WorkInfo} WorkInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a WorkInfo message.
         * @function verify
         * @memberof WorkInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.progress != null && message.hasOwnProperty("progress"))
                if (!$util.isInteger(message.progress))
                    return "progress: integer expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.finishedTime != null && message.hasOwnProperty("finishedTime"))
                if (!$util.isInteger(message.finishedTime) && !(message.finishedTime && $util.isInteger(message.finishedTime.low) && $util.isInteger(message.finishedTime.high)))
                    return "finishedTime: integer|Long expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 10:
                case 20:
                case 30:
                case 40:
                    break;
                }
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isString(message.result))
                    return "result: string expected";
            return null;
        };
    
        /**
         * Creates a WorkInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WorkInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {WorkInfo} WorkInfo
         */
        WorkInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.WorkInfo)
                return object;
            var message = new $root.WorkInfo();
            if (object.progress != null)
                message.progress = object.progress | 0;
            if (object.description != null)
                message.description = String(object.description);
            if (object.finishedTime != null)
                if ($util.Long)
                    (message.finishedTime = $util.Long.fromValue(object.finishedTime)).unsigned = false;
                else if (typeof object.finishedTime === "string")
                    message.finishedTime = parseInt(object.finishedTime, 10);
                else if (typeof object.finishedTime === "number")
                    message.finishedTime = object.finishedTime;
                else if (typeof object.finishedTime === "object")
                    message.finishedTime = new $util.LongBits(object.finishedTime.low >>> 0, object.finishedTime.high >>> 0).toNumber();
            switch (object.status) {
            case "NotRunned":
            case 0:
                message.status = 0;
                break;
            case "Queue":
            case 10:
                message.status = 10;
                break;
            case "InProgress":
            case 20:
                message.status = 20;
                break;
            case "Error":
            case 30:
                message.status = 30;
                break;
            case "Done":
            case 40:
                message.status = 40;
                break;
            }
            if (object.result != null)
                message.result = String(object.result);
            return message;
        };
    
        /**
         * Creates a plain object from a WorkInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WorkInfo
         * @static
         * @param {WorkInfo} message WorkInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.progress = 0;
                object.description = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.finishedTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.finishedTime = options.longs === String ? "0" : 0;
                object.status = options.enums === String ? "NotRunned" : 0;
                object.result = "";
            }
            if (message.progress != null && message.hasOwnProperty("progress"))
                object.progress = message.progress;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.finishedTime != null && message.hasOwnProperty("finishedTime"))
                if (typeof message.finishedTime === "number")
                    object.finishedTime = options.longs === String ? String(message.finishedTime) : message.finishedTime;
                else
                    object.finishedTime = options.longs === String ? $util.Long.prototype.toString.call(message.finishedTime) : options.longs === Number ? new $util.LongBits(message.finishedTime.low >>> 0, message.finishedTime.high >>> 0).toNumber() : message.finishedTime;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.EWorkStatus[message.status] : message.status;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };
    
        /**
         * Converts this WorkInfo to JSON.
         * @function toJSON
         * @memberof WorkInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return WorkInfo;
    })();
    
    /**
     * EWorkStatus enum.
     * @exports EWorkStatus
     * @enum {number}
     * @property {number} NotRunned=0 NotRunned value
     * @property {number} Queue=10 Queue value
     * @property {number} InProgress=20 InProgress value
     * @property {number} Error=30 Error value
     * @property {number} Done=40 Done value
     */
    $root.EWorkStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NotRunned"] = 0;
        values[valuesById[10] = "Queue"] = 10;
        values[valuesById[20] = "InProgress"] = 20;
        values[valuesById[30] = "Error"] = 30;
        values[valuesById[40] = "Done"] = 40;
        return values;
    })();

    return $root;
});
