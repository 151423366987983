import { Grid, Typography } from '@material-ui/core'
import { navigate } from '@reach/router'
import { withPrefix } from 'gatsby'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { IPharmacy } from '../../../../proto/models'
import {
  fetchPharmacy,
  initPharmacyEdit,
  onCreatePharmacy,
  onUpdatePharmacy,
} from '../../../../reducers/pharmacy/editPharmacyReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import PharmacyEditForm from '../PharmaciesEditForm'

interface IPharmaciesEditWindowProps {
  dispatch: AppDispatch
  loading: boolean
  pharmacy: IPharmacy
  id?: string
}

class PharmaciesEditWindow extends Component<IPharmaciesEditWindowProps> {
  constructor(props: IPharmaciesEditWindowProps) {
    super(props)
    this.loadData(this.props.id)
  }

  componentWillUnmount() {
    this.props.dispatch(initPharmacyEdit())
  }

  render() {
    if (this.props.loading) {
      return ''
    }

    return (
      <Grid container item direction={'column'} spacing={2} alignItems={'flex-start'} justify={'center'} xs>
        <Grid container item direction={'row'} xs={10}>
          <Typography variant={'h3'} color={'textPrimary'} component={'h3'}>
            Аптека
          </Typography>
        </Grid>
        <Grid container item direction={'row'} xs={10}>
          <PharmacyEditForm
            editMode={!!this.props.id}
            onBackBtnClick={this.onBackBtnClick}
            handleSubmit={this.onCreateBtnClick}
          />
        </Grid>
      </Grid>
    )
  }

  private loadData = (id: string) => {
    this.props.dispatch(fetchPharmacy(id))
  }

  private onCreateBtnClick = () => {
    if (!this.props.id) {
      this.props.dispatch(onCreatePharmacy())
    } else {
      this.props.dispatch(onUpdatePharmacy(this.props.id))
    }

    this.onBackBtnClick()
  }

  private onBackBtnClick = () => {
    navigate(withPrefix('/pharmacies'))
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    loading: store.pharmacies.edit.loading,
  }
}

export default connect(mapStateToProps, null)(PharmaciesEditWindow)
