import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React, { ReactElement } from 'react'

import RecordActionDialog, { RecordActionParams } from '@external/rp.ui/components/Modal/RecordActionModalDialog'
import { LocalizationHelper } from '@external/rp.ui/helpers/LocalizationHelper'
import { AuthError, AuthHelper, ProtoClient } from '@external/rp.ui/utils/protoClient'
import { Box, Button, Grid } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { Currencies, ICurrencies, ICurrencyList, PagingAndSort } from '../../../proto/models'
import { tableIcons } from '../../shared/tableIcons'

interface ICurrencyTableProps {
  onEditButtonClick: (rowData: ICurrencyList) => any
  onDeleteButtonClick: (rowData: ICurrencyList) => any
  onAddButtonClick: () => any
  syncComponent: ReactElement
  url: string
  tableRef: any
}

const fetchCurrencies = async (query: Query<ICurrencyList>, url: string): Promise<QueryResult<ICurrencyList>> => {
  const paging = PagingAndSort.create({
    pageIndex: query.page,
    pageSize: query.pageSize,
    sortColumn: query.orderBy?.field ?? 'name',
    sortDirection: query.orderDirection ?? 'asc',
    searchQuery: query.search,
  })

  try {
    const currencies = await ProtoClient.get<ICurrencies>(url, Currencies, paging)

    return {
      data: currencies?.items ?? [],
      page: query.page,
      totalCount: currencies?.rowCount ?? 0,
    }
  } catch (error) {
    if (error instanceof AuthError) {
      AuthHelper.redirect(error.fullCallbackUrl)
    }
    throw error
  }
}

export default (props: ICurrencyTableProps) => {
  const [isLoading, setLoading] = React.useState(false)

  const ConfirmDeletionModalButton = (props: {
    rowData: any
    onAgreeBtnClick: any
    tableRef: any
  }): React.ReactElement<RecordActionParams> => {
    const modalParams: RecordActionParams = {
      agreeCallbackArgs: props.rowData,
      button: (
        <Fab onClick={async () => {}} size="small" color="secondary" aria-label="delete" style={{ margin: 0 }}>
          <DeleteIcon />
        </Fab>
      ),
      title: '',
      body: 'Вы уверены, что хотите Удалить \n запись справочника?',
      agreeString: 'Да',
      disagreeString: 'Нет',
      agreeCallback: async (rowData) => {
        setLoading(true)
        await props.onAgreeBtnClick(rowData)
        props.tableRef.current && props.tableRef.current.onQueryChange()
        setLoading(false)
      },
      disagreeCallback(): void {},
    }
    return RecordActionDialog(modalParams)
  }

  return (
    <MaterialTable<ICurrencyList>
      title="Валюты"
      isLoading={isLoading}
      tableRef={props.tableRef}
      localization={LocalizationHelper.GetLocalization()}
      icons={tableIcons}
      columns={[
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          render: (rowData) => (
            <Fab
              onClick={() => props.onEditButtonClick(rowData)}
              size="small"
              color="primary"
              aria-label="edit"
              style={{ margin: 0 }}
            >
              <EditIcon />
            </Fab>
          ),
        },
        { title: 'Наименование валюты', field: 'name', sorting: true },
        { title: 'Буквенный код', field: 'code', sorting: true },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'delete',
          render: (rowData) => (
            <ConfirmDeletionModalButton
              onAgreeBtnClick={props.onDeleteButtonClick}
              rowData={rowData}
              tableRef={props.tableRef}
            />
          ),
        },
      ]}
      options={{
        sorting: true,
        search: false,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        searchFieldAlignment: 'left',
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchCurrencies(query, props.url)}
      components={{
        Toolbar: (toolbarProps) => (
          <Box>
            <MTableToolbar {...toolbarProps} />
            <Box ml={2}>
              <Grid container direction={'row'} alignItems={'flex-start'}>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={props.onAddButtonClick}>
                    Добавить
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  {props.syncComponent}
                </Grid>
              </Grid>
            </Box>
          </Box>
        ),
      }}
    />
  )
}
