import { WebClient } from '@external/rp.ui/utils/protoClient'
import { AppDispatch, IAppState } from '../rootReducer'
import { EWorkStatus, IWorkInfo } from '../../proto/models'
import { checkStatus, CheckStatusDispose } from '@external/rp.ui/utils/workStatusChecker'
import { createReducer } from '@reduxjs/toolkit'

export interface IDrugsSyncState {
  workStatus: IWorkInfo
  timer: CheckStatusDispose
}

const initialState: IDrugsSyncState = {
  workStatus: null,
  timer: null,
}

const drugsUrl = 'dictionaries/drugs'

const SYNC_DRUGS_STATUS = 'SYNC_DRUGS_STATUS'
export const syncDrugs = () => (dispatch: AppDispatch, getState: () => IAppState) => {
  WebClient.request(drugsUrl + '/sync', 'POST')

  var status: IWorkInfo = {
    status: EWorkStatus.Queue,
    progress: 0,
  }

  dispatch({
    type: SYNC_DRUGS_STATUS,
    payload: status,
  })

  dispatch(checkSyncStatus())
}

const SET_TIMER = 'DRUGS_SET_TIMER'
const CHECK_SYNC_STATUS = 'DRUGS_CHECK_SYNC_STATUS'
export const checkSyncStatus = () => (dispatch: AppDispatch, getState: () => IAppState) => {
  const id = checkStatus(drugsUrl + '/sync-progress', (value) => dispatch({ type: CHECK_SYNC_STATUS, payload: value }))
  dispatch(clearTimer())
  dispatch({ type: SET_TIMER, payload: id })
}

export const clearTimer = () => (dispatch: AppDispatch, getState: () => IAppState) => {
  const timer = getState().drugs.sync.timer
  if (typeof timer === 'function') {
    timer()
  }
}

export default createReducer(initialState, {
  [SYNC_DRUGS_STATUS]: (state, action) => {
    state.workStatus = action.payload
  },
  [CHECK_SYNC_STATUS]: (state, action) => {
    state.workStatus = action.payload
  },
  [SET_TIMER]: (state, action) => {
    state.timer = action.payload
  },
})
