import { Dispatch } from 'redux'

import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { IPharmaciesNetworks, PharmaciesNetworks } from '../../../proto/models'
import { ISelectState } from '../../types/types'

const addressesUrl = 'dictionaries/pharmaciesnetworks'

export interface IPharmaciesNetworkEditState {
  pharmaciesNetwork: ISelectState[]
}

const initialState: IPharmaciesNetworkEditState = {
  pharmaciesNetwork: [],
}

const PHARMACIES_NETWORK_LOADED = 'PHARMACIES_NETWORK_LOADED'
const pharmaciesNetworkLoaded = (pharmaciesNetwork: IPharmaciesNetworks) => ({
  type: PHARMACIES_NETWORK_LOADED,
  payload: pharmaciesNetwork.items.map((item) => ({ label: item.name, value: item.id })),
})

export const fetchPharmaciesNetwork = () => async (dispatch: Dispatch) => {
  const pharmaciesNetwork = await ProtoClient.get<IPharmaciesNetworks>(
    addressesUrl + '/get-pharmacies-network',
    PharmaciesNetworks,
    {}
  )

  dispatch(pharmaciesNetworkLoaded(pharmaciesNetwork))
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PHARMACIES_NETWORK_LOADED:
      return { ...state, pharmaciesNetwork: action.payload }
    default:
      return state
  }
}
