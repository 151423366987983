import { EWorkStatus, IWorkInfo, WorkInfo } from '../proto/models'
import { ProtoClient } from './protoClient'

export type CheckStatusDispose = () => void

export function checkStatus(
  url: string,
  next: (value: IWorkInfo) => void,
  params: any = {},
  firstCallDelay = 5 * 1000
): CheckStatusDispose {
  let id: number

  const checkWorkStatus = async () => {
    try {
      const workInfo = await ProtoClient.get<IWorkInfo>(url, WorkInfo, params)

      next(workInfo)

      if (workInfo.status === EWorkStatus.InProgress || workInfo.status === EWorkStatus.Queue) {
        id = window.setTimeout(checkWorkStatus, 5 * 1000)
      }
    } catch (reason) {
      next(getError(reason))
    }
  }

  id = window.setTimeout(checkWorkStatus, firstCallDelay)

  return () => {
    clearTimeout(id)
  }
}

function getError(reason: any): IWorkInfo {
  return {
    status: EWorkStatus.Error,
    description: reason,
    progress: 0,
  }
}
