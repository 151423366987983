import { withPrefix } from 'gatsby'

import { HttpHelper } from '../helpers/httpHelper'
import { ProtoHelper } from '../helpers/protoHelper'

//используется везде
export class ProtoClient {
  private constructor() {}

  private static apiPrefix = withPrefix('/api/')

  public static async get<TResult>(
    relativeUrl: string,
    responseProtoType: any,
    params: any = {},
    customPrefix: string = null
  ): Promise<TResult> {
    const prefix = customPrefix ? withPrefix(customPrefix) : this.apiPrefix
    const url = HttpHelper.constructUrl(prefix, relativeUrl)
    const options = ProtoHelper.setOptions(HttpHelper.defaultOptions)
    const request = HttpHelper.getBefore(url, options, params)

    const response = await HttpHelper.sendRequest(request)

    return ProtoHelper.requestAfter<TResult>(response, responseProtoType)
  }

  public static async post<TResult>(
    relativeUrl: string,
    data: any,
    requestProtoType: any,
    responseProtoType: any = null,
    customPrefix: string = null
  ): Promise<TResult> {
    const prefix = customPrefix ? withPrefix(customPrefix) : this.apiPrefix
    const url = HttpHelper.constructUrl(prefix, relativeUrl)
    const options = ProtoHelper.setOptions(HttpHelper.defaultOptions)
    const request = HttpHelper.postBefore(url, data, requestProtoType, options)

    const response = await HttpHelper.sendRequest(request)

    return ProtoHelper.requestAfter<TResult>(response, responseProtoType)
  }

  public static async put<TResult>(
    relativeUrl: string,
    data: any,
    requestProtoType: any,
    responseProtoType: any = null,
    customPrefix: string = null
  ): Promise<TResult> {
    const prefix = customPrefix ? withPrefix(customPrefix) : this.apiPrefix
    const url = HttpHelper.constructUrl(prefix, relativeUrl)
    const options = ProtoHelper.setOptions(HttpHelper.defaultOptions)

    const request = HttpHelper.putBefore(url, data, requestProtoType, options)

    const response = await HttpHelper.sendRequest(request)

    return ProtoHelper.requestAfter<TResult>(response, responseProtoType)
  }

  public static async delete<TResult>(
    relativeUrl: string,
    data: any,
    responseProtoType: any = null,
    customPrefix: string = null
  ): Promise<TResult> {
    const prefix = customPrefix ? withPrefix(customPrefix) : this.apiPrefix
    const url = HttpHelper.constructUrl(prefix, relativeUrl)
    const options = ProtoHelper.setOptions(HttpHelper.defaultOptions)

    const request = HttpHelper.deleteBefore(url, data, options)

    const response = await HttpHelper.sendRequest(request)

    return ProtoHelper.requestAfter<TResult>(response, responseProtoType)
  }
}
