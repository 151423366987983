import React, { SyntheticEvent } from 'react'
import { Button } from '@material-ui/core'
import styles from './UploadFile.module.scss'
import PropTypes from 'prop-types'

interface IUploadContext {
  accept: string
  onChange(event: SyntheticEvent): void
}

const UploadFile = (props: IUploadContext) => {
  return (
    <div className={styles.uploadWrapper}>
      <form>
        <input
          accept={props.accept}
          className={styles.input}
          id="contained-button-file"
          type="file"
          onChange={props.onChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            Импорт 2ГИС
          </Button>
        </label>
      </form>
    </div>
  )
}

UploadFile.propTypes = {
  accept: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default UploadFile
