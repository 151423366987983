import { withPrefix } from 'gatsby'
import { HttpHelper } from '../helpers/httpHelper'

export class WebClient {
  private constructor() {}

  private static apiPrefix = withPrefix('/api/')

  public static async request(
    relativeUrl: string,
    method: string,
    options: any = {},
    params: any = null
  ): Promise<Response> {
    const url = HttpHelper.constructUrl(this.apiPrefix, relativeUrl)

    const request = HttpHelper.request(url, method, options, params)

    return await HttpHelper.sendRequest(request)
  }
}
