import { Button } from '@material-ui/core'
import { checkStatus, CheckStatusDispose } from '../../utils/workStatusChecker'
import React, { useRef, useState, useEffect, useCallback } from 'react'
import AsyncWork from './AsyncWork'
import { WebClient } from '../../utils/protoClient'
import { EWorkStatus, IWorkInfo } from '../../proto/models'

const AsyncWorkButton = ({
  urlSyncStart,
  urlSyncCheck,
  text,
}: {
  urlSyncStart: string
  urlSyncCheck: string
  text: string
}) => {
  const [isSending, setIsSending] = useState(true)
  const [syncStatus, setSyncStatus] = useState<IWorkInfo>({
    status: EWorkStatus.NotRunned,
  })
  const isMounted = useRef(true)

  let dispose: CheckStatusDispose

  useEffect(() => {
    dispose = checkStatus(urlSyncCheck, (value) => {
      setSyncStatus(value)
      if (isMounted.current && value.status !== EWorkStatus.InProgress && value.status !== EWorkStatus.Queue) {
        setIsSending(false)
      }
    })
    return () => {
      dispose()
      isMounted.current = false
    }
  }, [])

  const sendRequest = useCallback(async () => {
    if (isSending) {
      return
    }

    setIsSending(true)

    await WebClient.request(urlSyncStart, 'POST')

    if (syncStatus.status !== EWorkStatus.InProgress && syncStatus.status !== EWorkStatus.Queue) {
      dispose = checkStatus(urlSyncCheck, (value) => setSyncStatus(value))
    }

    if (isMounted.current) {
      setIsSending(false)
    }
  }, [isSending])

  const button = (
    <Button variant="contained" color="primary" onClick={sendRequest}>
      {text}
    </Button>
  )

  return <AsyncWork workStatus={syncStatus} startWorkElement={button} />
}

export default AsyncWorkButton
