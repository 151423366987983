import AwesomeDebouncePromise from 'awesome-debounce-promise'
import React from 'react'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'
import { createTextMask } from 'redux-form-input-masks'

import { TextField } from '@external/rp.ui/components/MaterialReduxForm'
import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  AutocompleteSearchResults,
  IAutocompleteSearchResult,
  IAutocompleteSearchResults,
  ICountryCallingCode,
} from '../../../proto/models'

export interface ICountryCallingCodeEditWindowContext {
  handleClose: (values: any) => void
  createButtonClickHandler: (e: any) => void
  isOpen: boolean
  url: string
}

const onSearchValueChange = async (search: string, url: string): Promise<IAutocompleteSearchResult[]> => {
  if (!search || search === '') {
    return []
  }
  const res = await ProtoClient.get<IAutocompleteSearchResults>(
    url + '/search-country-name',
    AutocompleteSearchResults,
    {
      search: search,
    }
  )
  return res?.SearchResults ?? []
}

const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 500)

const AutocompleteField = (props: any) => {
  const [options, setOptions] = React.useState<IAutocompleteSearchResult[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const {
    input: { onChange },
  } = props

  return (
    <Autocomplete
      multiple={false}
      freeSolo
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setLoading(true)
          searchAPIDebounced(value, props.url).then((res) => {
            setOptions(res)
            setLoading(false)
            setOpen(true)
          })
        }
        if (reason === 'clear') {
          setOptions([])
          setLoading(false)
        }
      }}
      loading={loading}
      getOptionSelected={(option, value) => option.name === value.name}
      options={options}
      renderOption={(option) => option.name}
      filterOptions={(options, state) => options}
      getOptionLabel={(option) => option.name}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      defaultValue={props.input.value}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          meta={{ ...props.meta }}
          {...params}
          placeholder={'Выберите страну'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

const required = (value: any) => (value ? undefined : 'Не заполнено обязательное поле')

const phoneMask = createTextMask({
  pattern: '+999999',
  guide: false,
})

const EditForm = (
  props: ICountryCallingCodeEditWindowContext &
    InjectedFormProps<ICountryCallingCode, ICountryCallingCodeEditWindowContext, string>
) => {
  const { handleSubmit, submitting } = props

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>Телефонные коды</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Телефонный код</FormLabel>
              <Field
                placeholder={'Введите телефонный код'}
                name={'callingCode'}
                component={TextField}
                validate={required}
                {...phoneMask}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Страна</FormLabel>
              <Field name={'countryName'} component={AutocompleteField} validate={required} url={props.url} />
            </FormControl>
          </Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.createButtonClickHandler} disabled={submitting}>
          Продолжить
        </Button>

        <Button variant="contained" color="primary" onClick={props.handleClose}>
          Назад
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CountryCallingCodeEditForm = reduxForm<ICountryCallingCode, ICountryCallingCodeEditWindowContext>({
  form: 'CountryCallingCodeEditForm',
})(EditForm)

export default CountryCallingCodeEditForm
