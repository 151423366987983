import { IWorkInfo, EWorkStatus } from '../../proto/models'
import { createReducer } from '@reduxjs/toolkit'
import { AppDispatch, IAppState } from '../rootReducer'
import { WebClient } from '@external/rp.ui/utils/protoClient'
import { checkStatus, CheckStatusDispose } from '@external/rp.ui/utils/workStatusChecker'

export interface ICountryCallingCodeSyncState {
  workStatus: IWorkInfo
  timer: CheckStatusDispose
}

const initialState: ICountryCallingCodeSyncState = {
  workStatus: null,
  timer: null,
}

const url = 'dictionaries/country-calling-codes'

export const syncCountryCallingCode = () => (dispatch: AppDispatch) => {
  WebClient.request(url + '/sync', 'POST')

  var status: IWorkInfo = {
    status: EWorkStatus.Queue,
    progress: 0,
  }

  dispatch({
    type: CHECK_IMPORT_STATUS,
    payload: status,
  })

  dispatch(checkCountryCallingCodeSyncStatus())
}

export const checkCountryCallingCodeSyncStatus = () => (dispatch: AppDispatch) => {
  const dispose = checkStatus(url + '/sync-progress', (value) =>
    dispatch({ type: CHECK_IMPORT_STATUS, payload: value })
  )
  dispatch(clearTimer())
  dispatch({ type: SET_TIMER, payload: dispose })
}

export const clearTimer = () => (dispatch: AppDispatch, getState: () => IAppState) => {
  const timer = getState().countryCallingCodes.sync.timer
  if (typeof timer === 'function') {
    timer()
  }
}

export const CHECK_IMPORT_STATUS = 'CALLING_CODE_CHECK_IMPORT_STATUS'
export const SET_TIMER = 'CALLING_CODE_SET_TIMER'

const CountryCallingCodeSyncReducer = createReducer(initialState, {
  [CHECK_IMPORT_STATUS]: (state, action) => {
    state.workStatus = action.payload
  },
  [SET_TIMER]: (state, action) => {
    state.timer = action.payload
  },
})

export default CountryCallingCodeSyncReducer
