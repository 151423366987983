import { withPrefix } from 'gatsby'
import MaterialTable, { MTableToolbar } from 'material-table'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import AsyncWork, { IAsyncWorkContext } from '@external/rp.ui/components/AsyncWork/AsyncWork'
import { GuidHelper } from '@external/rp.ui/helpers/GuidHelper'
import { Button, TablePagination } from '@material-ui/core'
import { navigate } from '@reach/router'

import { IPharmacy, IWorkInfo } from '../../../../proto/models'
import {
  fetchPharmacies,
  onChangePageIndex,
  onChangePageSize,
  onOrderChange,
  removePharmacy,
} from '../../../../reducers/pharmacy/listPharmacyReducer'
import { clearTimer, syncPharmacy } from '../../../../reducers/pharmacy/pharmacySyncReducer'
import { IAppState } from '../../../../reducers/rootReducer'
import DeleteButton from '../../../buttons/DeleteButton'
import EditButton from '../../../buttons/EditButton'
import { tableIcons } from '../../../shared/tableIcons'
import styles from './PharmaciesDictionary.module.scss'

interface IPharmaciesContext {
  dispatch: Dispatch
  loading: boolean
  pharmacies: IPharmacy[]
  rowCount: number
  paging: {
    pageIndex: number
    pageSize: number
  }
  syncStatus: IWorkInfo
}

class PharmaciesDictionary extends Component<IPharmaciesContext> {
  private editWindowPath = '/pharmacies/edit'
  private pharmacyUrl = '/dictionaries/pharmacies'

  constructor(private props: IPharmaciesContext) {
    super(props)

    this.loadData()
  }

  componentWillUnmount() {
    this.props.dispatch(clearTimer())
  }

  private syncComponent = () => {
    const syncButton = (
      <Button variant="contained" color="primary" onClick={() => this.props.dispatch(syncPharmacy())}>
        Синхронизация
      </Button>
    )
    const asyncWorkProps: IAsyncWorkContext = {
      workStatus: this.props.syncStatus,
      startWorkElement: syncButton,
    }
    return <AsyncWork {...asyncWorkProps} />
  }

  render() {
    return (
      <MaterialTable
        title="Аптеки"
        data={this.props.pharmacies}
        icons={tableIcons}
        columns={this.columns}
        isLoading={this.props.loading}
        options={{
          search: false,
          emptyRowsWhenPaging: false,
          showTextRowsSelected: false,
          pageSize: this.props.paging.pageSize,
          pageSizeOptions: [25, 50, 75],
          filtering: true,
        }}
        onOrderChange={this.onOrderChange}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div className={styles.toolbarWrapper}>
                <div className={styles.buttonWrapper}>
                  <Button variant="contained" color="primary" onClick={this.onUpdateButtonClick}>
                    Обновить
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button variant="contained" color="primary" onClick={this.onCreateButtonClick}>
                    Добавить
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <this.syncComponent />
                </div>
              </div>
            </div>
          ),
          Pagination: (props) => (
            <TablePagination
              {...props}
              count={this.props.rowCount}
              page={this.props.paging.pageIndex}
              onChangePage={(e, page) => {
                this.onChangePage(page)
              }}
            />
          ),
        }}
      />
    )
  }

  private onEditButtonClick = (id: Uint8Array) => {
    const path = `${this.editWindowPath}/${GuidHelper.toString(id)}`
    navigate(withPrefix(path))
  }

  private onDeleteButtonClick = (id: Uint8Array) => {
    this.props.dispatch(removePharmacy(GuidHelper.toString(id)))
  }

  private columns = [
    {
      title: '',
      field: 'edit',
      filtering: false,
      render: (rowData: { id: Uint8Array }) => <EditButton onClick={(_) => this.onEditButtonClick(rowData.id)} />,
    },
    { title: 'Наименование', field: 'name' },
    { title: 'Страна', field: 'countryName' },
    { title: 'Регион', field: 'regionName' },
    { title: 'Населенный пункт', field: 'settlementName' },
    { title: 'Улица', field: 'streetName' },
    { title: 'Дом', field: 'houseNumber' },
    { title: 'Аптечная сеть', field: 'pharmaciesNetwork' },
    {
      title: '',
      field: 'delete',
      filtering: false,
      render: (rowData: { id: Uint8Array }) => <DeleteButton onClick={(_) => this.onDeleteButtonClick(rowData.id)} />,
    },
  ]

  private onCreateButtonClick = () => {
    navigate(withPrefix(this.editWindowPath))
  }

  private onUpdateButtonClick = () => {
    this.loadData()
  }

  private loadData = () => {
    this.props.dispatch(fetchPharmacies())
  }

  private onOrderChange = (orderBy: number, orderDirection: 'asc' | 'desc') => {
    this.props.dispatch(onOrderChange(orderBy < 0 ? '' : this.columns[orderBy].field, orderDirection))
  }

  private onChangeRowsPerPage = (pageSize: number) => {
    this.props.dispatch(onChangePageSize(pageSize))
  }

  private onChangePage = (pageIndex: number) => {
    this.props.dispatch(onChangePageIndex(pageIndex))
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    loading: store.pharmacies.list.loading,
    pharmacies: store.pharmacies.list.items,
    rowCount: store.pharmacies.list.rowCount,
    paging: store.pharmacies.list.paging,
    syncStatus: store.pharmacies.sync.workStatus,
  }
}

export default connect(mapStateToProps, null)(PharmaciesDictionary)
