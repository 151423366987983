import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { initialize, SubmissionError } from 'redux-form'

import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { NosologyFormOwnProps } from '../../components/dictionaries/nosologies/NosologyEditWindow'
import { INosologyForm, NosologyForm } from '../../proto/models'

export type NosologyEditFormState = {
  loading: boolean
}

const initState: NosologyEditFormState = {
  loading: false,
}

const SET_NOSOLOGY_LOADING = 'SET_NOSOLOGY_LOADING'
export const setNosologyLoading = createAction<boolean>(SET_NOSOLOGY_LOADING)

const GET_NOSOLOGY = 'GET_NOSOLOGY'
export const getNosology = createAsyncThunk<void, string>(GET_NOSOLOGY, async (id, api) => {
  const response = await ProtoClient.get<INosologyForm>('dictionaries/nosologies/edit', NosologyForm, {
    id: id,
  })

  if (response.synonyms) {
    response.synonyms.push(null)
  } else {
    response.synonyms = [null]
  }
  api.dispatch(initialize('nosologyEditForm', response))
})

export const saveNosology = async (nosology: INosologyForm, dispatch: any, props: NosologyFormOwnProps) => {
  const newNosology = { ...nosology }
  if (newNosology.synonyms) {
    newNosology.synonyms = nosology.synonyms.filter((v) => v !== null && v !== '' && typeof v !== 'undefined')
  }
  if (props.id) {
    const nosologyToUpdate = NosologyForm.create(newNosology)

    const response = await ProtoClient.post<INosologyForm>(
      'dictionaries/nosologies',
      nosologyToUpdate,
      NosologyForm,
      NosologyForm
    )
    if (response.errorMessage) {
      throw new SubmissionError({ _error: response.errorMessage })
    }
  } else {
    const nosologyToCreate = NosologyForm.create(newNosology)

    var response = await ProtoClient.put<INosologyForm>(
      'dictionaries/nosologies',
      nosologyToCreate,
      NosologyForm,
      NosologyForm
    )

    if (response.errorMessage) {
      throw new SubmissionError({ _error: response.errorMessage })
    }
  }
}

const nosologyReducer = createReducer(initState, {
  [getNosology.pending.toString()]: (state, action) => {
    state.loading = true
  },
  [getNosology.rejected.toString()]: (state, action) => {
    state.loading = false
  },
  [getNosology.fulfilled.toString()]: (state, action) => {
    state.loading = false
  },
  [SET_NOSOLOGY_LOADING]: (state, action) => {
    state.loading = action.payload
  },
})

export default nosologyReducer
