import React from 'react';
import { ISelectedDto } from 'src/proto/models';

import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export default (props: any) => {
  const [options, setOptions] = React.useState<ISelectedDto[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const { onChange, value } = props.input

  return (
    <Autocomplete
      multiple={false}
      freeSolo
      style={{ width: props.width ? props.width : 400 }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setLoading(true)
          props.searchAPIDebounced(value).then((res) => {
            setOptions(res)
            setLoading(false)
            setOpen(true)
          })
        }
        if (reason === 'clear') {
          setOptions([])
          setLoading(false)
        }
      }}
      loading={loading}
      renderOption={props.renderOption}
      getOptionSelected={props.getOptionSelected}
      getOptionLabel={props.getOptionLabel}
      options={options}
      filterOptions={(options, state) => options}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      value={value}
      onChange={(event, value) => onChange(value)}
      renderInput={(params) => {
        return (
        <TextField
          {...params}
          variant="outlined"
          value={props.getValue(value)}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />)
}}
    />
  )
}
