import React from 'react'
import { Fab } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import styles from './DeleteButton.module.scss'

interface IDeleteButtonContext {
  onClick(data: any): void
}

const DeleteButton = (props: IDeleteButtonContext) => {
  return (
    <Fab onClick={props.onClick} size="small" color="secondary" aria-label="delete" className={styles.deleteButton}>
      <DeleteIcon />
    </Fab>
  )
}

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default DeleteButton
