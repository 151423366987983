import { TextField } from "@external/rp.ui/components/MaterialReduxForm"
import { Button, CircularProgress, Collapse, FormControl, FormLabel, IconButton, Typography, Divider } from "@material-ui/core"
import { FormErrors, initialize, WrappedFieldArrayProps } from "redux-form"
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { arrayPush, Field, FieldArray, Form, InjectedFormProps, reduxForm, } from 'redux-form'
import { AppDispatch, IAppState } from "src/reducers/rootReducer"
import { IDrugFormDto } from "src/proto/models"
import { createDrug, DrugFormOwnProps, fetchDrug, setDrugLoading, setErrorMessage } from "src/reducers/drugs/drugReducer"
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from "@material-ui/lab"
import styles from './DrugsEditWindow.module.scss'
import AutocompleteField from "../AutocompleteField"
import AwesomeDebouncePromise from "awesome-debounce-promise"
import { DrugSearchService } from "src/services/drug/drugSearchService"

const toUppercase = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

const regex = /^-?\d*[.]?\d*$/
const replace = (value: string, prevValue: string) => {
  debugger
  if(regex.test(value)) {
    return value
  }

  return prevValue
}

const renderDrugNameSymptoms = ({ fields }: WrappedFieldArrayProps) => {
  return (
    <>
      {fields.map((drugNameSynonym, index) => (
        <Field key={index} name={drugNameSynonym} component={TextField} normalize={toUppercase} variant="outlined" />
      ))
      }
    </>)
}

const AddDosageButton = (props: { drug: string, push: (field: string, value: any) => void }) => (
  <Button
    color='primary'
    variant='contained'
    onClick={() => props.push(`${props.drug}.dosages`, {})}>Добавить дозировку</Button>
)

const AtcGroup = (props: { drug: string }) => (
  <FormControl>
    <div className={styles.formFieldWrapper}>
      <FormLabel className={styles.formLabel} component="legend">АТХ</FormLabel>
      <Field
        placeholder="Выберите АТХ"
        name={`${props.drug}.atcGroup`}
        component={AutocompleteField}
        getValue={(value: any) => value.code}
        renderOption={(option: any) => option.code}
        getOptionLabel={(option: any) => option.code ? option.code : ''}
        getOptionSelected={((option: any, value: any) => option.code === value.code)}
        searchAPIDebounced={AwesomeDebouncePromise(DrugSearchService.onAtcGroupChange, 500)} />
    </div>
  </FormControl>
)

const Ptg = (props: { drug: string }) => (
  <FormControl>
    <div className={styles.formFieldWrapper}>
      <FormLabel className={styles.formLabel} component="legend">ФТГ</FormLabel>
      <Field
        placeholder="Выберите ФТГ"
        name={`${props.drug}.ptg`}
        component={AutocompleteField}
        getValue={(value: any) => value.name}
        renderOption={(option: any) => option.name}
        getOptionLabel={(option: any) => option.name ? option.name : ''}
        getOptionSelected={((option: any, value: any) => option.name === value.name)}
        searchAPIDebounced={AwesomeDebouncePromise(DrugSearchService.onPtgChange, 500)} />
    </div>
  </FormControl>
)

const DrugFormFull = (props: { drug: string }) => (
  <FormControl>
    <div className={styles.formFieldWrapper}>
      <FormLabel className={styles.formLabel} component="legend">Лекарственная форма</FormLabel>
      <Field
        placeholder="Выберите лекарственную форму"
        name={`${props.drug}.drugFormFull`}
        component={AutocompleteField}
        getValue={(value: any) => value.name}
        renderOption={(option: any) => option.name}
        getOptionLabel={(option: any) => option.name ? option.name : ''}
        getOptionSelected={((option: any, value: any) => option.name === value.name)}
        searchAPIDebounced={AwesomeDebouncePromise(DrugSearchService.onDrugFormFullChange, 500)} />
    </div>
  </FormControl>
)

const InnPart = (props: { dosage: string }) => (
  <FormControl>
    <div className={styles.formFieldWrapper}>
      <FormLabel className={styles.formLabel} component="legend">Действующее вещество</FormLabel>
      <Field
        placeholder="Выберите действующее вещество"
        name={`${props.dosage}.innPart`}
        component={AutocompleteField}
        getValue={(value: any) => value.name}
        renderOption={(option: any) => option.name}
        getOptionLabel={(option: any) => option.name ? option.name : ''}
        getOptionSelected={((option: any, value: any) => option.name === value.name)}
        searchAPIDebounced={AwesomeDebouncePromise(DrugSearchService.onInnPartChange, 500)} />
    </div>
  </FormControl>
)

const DrugDosagePart = (props: any) => (
  <FormControl>
    <div className={styles.formFieldWrapper}>
      <FormLabel className={styles.formLabel} component="legend">Дозировка</FormLabel>
      <Field
        placeholder="Дозировка"
        style={{ marginRight: 10, width: 150 }}
        variant="outlined"
        name={`${props.dosage}.drugDosagePart.value`}
        normalize={replace}
        component={TextField} />

      <FormLabel className={styles.formLabel} component="legend">Единица измерения</FormLabel>
      <div style={{ width: '50px' }}>
        <Field
          width={200}
          placeholder="Ед. измерения"
          name={`${props.dosage}.drugDosagePart.measure`}
          component={AutocompleteField}
          getValue={(value: any) => value.name}
          renderOption={(option: any) => option.name}
          getOptionLabel={(option: any) => option.name ? option.name : ''}
          getOptionSelected={((option: any, value: any) => option.name === value.name)}
          searchAPIDebounced={AwesomeDebouncePromise(DrugSearchService.onMeasureChange, 500)} />
      </div>

    </div>
  </FormControl>
)

const Dosages = ({ fields }: WrappedFieldArrayProps) => (
  <>
    {fields.map((dosage: string) => (
      <div className={styles.inputWrapper}>
        <div className={styles.formControlWrapper}>
          <InnPart dosage={dosage} />
        </div>
        <div className={styles.formControlWrapper}>
          <DrugDosagePart dosage={dosage} />
        </div>
      </div>
    ))}
    <div className={styles.divider}>
      <Divider />
    </div>
  </>
)

const DrugName = (props: { drugNameId: string }) => (
  <FormControl>
    <div className="field-wrapper" style={{ display: 'flex', alignItems: 'center' }}>
      <FormLabel style={{ fontSize: 20, marginRight: 10 }} component="legend" >Наименование</FormLabel>
      <Field
        className={styles.drugName}
        disabled={!!props.drugNameId}
        variant="outlined"
        name="drugName"
        component={TextField}
        normalize={toUppercase}
      />
    </div>
  </FormControl>
)

const DrugEditWindow = (props: DrugFormProps) => {
  const { loading, errorMessage, dispatch, array: { push }, drugNameId, handleSubmit, submitting, pristine } = props

  useEffect(() => {
    if (drugNameId) {
      dispatch(setDrugLoading(true))
      dispatch(fetchDrug(drugNameId))
    } else {
      dispatch(arrayPush(formName, 'drugNameSynonyms', null))
    }

    return () => {
      dispatch(setDrugLoading(false))
      dispatch(setErrorMessage(null))
    }
  }, [])

  const onBackBtnClick = (e: any) => {
    dispatch(setErrorMessage(null))
    navigateToDictionary()
  }

  const navigateToDictionary = () => navigate('/drugs')

  const ErrorAlert = () => (
    <Collapse in={!!errorMessage}>
      <Alert severity="error"
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={() => { dispatch(setErrorMessage(null)) }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }>
        {errorMessage}
      </Alert>
    </Collapse>
  )

  return loading
    ? (<CircularProgress />)
    : (
      <>
        <ErrorAlert />
        <>
          <Form onSubmit={handleSubmit}>
            <div className={styles.drugTitle}>
              <Typography variant="h6" noWrap>Препарат</Typography>
            </div>
            <div>
              <div className={styles.drugButton}>
                <Button variant="contained" color="primary" onClick={onBackBtnClick} type="button">
                  Назад
                </Button>
              </div>
              <div className={styles.drugButton}>
                <Button variant="contained" color="primary" className={styles.drugButton} type="submit" disabled={submitting || pristine}>
                  Сохранить
                </Button>
              </div>
              <div className={styles.drugButton}>
                <Button variant="contained" color="primary" onClick={() => push('drugs', {})} className={styles.drugButton}>
                  Добавить
                </Button>
              </div>
            </div>
            <div className={styles.formControlWrapper}>
              <DrugName drugNameId={drugNameId} />
            </div>
            <>
              <FieldArray
                name="drugs"
                component={({ fields }: WrappedFieldArrayProps) => (
                  <>
                    {fields.map((drug: string) => (
                      <>
                        <div className={styles.formControlWrapper}>
                          <AtcGroup drug={drug} />
                        </div>
                        <div className={styles.formControlWrapper}>
                          <Ptg drug={drug} />
                        </div>
                        <div className={styles.formControlWrapper}>
                          <DrugFormFull drug={drug} />
                        </div>
                        <div className={styles.formControlWrapper}>
                          <AddDosageButton drug={drug} push={push} />
                        </div>

                        <FieldArray
                          name={`${drug}.dosages`}
                          component={Dosages}
                          props={{ dispatch: dispatch, push: push }}
                        />
                      </>
                    ))
                    }
                  </>)}
                props={{ dispatch: dispatch, push: push }}
              />
            </>

            <div className={styles.drugTitle}>
              <Typography variant="h6" noWrap>
                Синонимы
              </Typography>
            </div>
            <div>
              <FieldArray
                name="drugNameSynonyms"
                component={renderDrugNameSymptoms}
                props={{ dispatch: dispatch, push: push }}
              />
            </div>
          </Form>
        </>
      </>
    )
}

type DrugInjectedStateProps = {
  dispatch?: AppDispatch
  loading: boolean
  errorMessage?: string
}

type DrugFormProps = InjectedFormProps<IDrugFormDto, DrugFormOwnProps & DrugInjectedStateProps> &
  DrugFormOwnProps &
  DrugInjectedStateProps

const formName = 'drugEditForm'

const validate = (values: IDrugFormDto): FormErrors<IDrugFormDto, string> => {
  const errors: FormErrors<IDrugFormDto, string> = {}

  if (!values.drugName) {
    errors.drugName = 'Обязательно для заполнения'
  } else if (!values.drugName.trim()) {
    errors.drugName = 'Поле содержит только пробелы'
  }

  return errors
}

const editReduxForm = reduxForm<IDrugFormDto, DrugFormOwnProps & DrugInjectedStateProps>({
  form: formName,
  onSubmit: createDrug,
  validate,
  onSubmitSuccess: (result, dispatch, props) => {
    navigate(`/drugs`)
  },
  onSubmitFail: (errors, dispatch, props) => {
    dispatch(setErrorMessage(errors._error))
  },
  onChange: (values, dispatch, props, prev) => {
    if (
      values.drugNameSynonyms &&
      prev.drugNameSynonyms &&
      values.drugNameSynonyms.length > 0 &&
      prev.drugNameSynonyms.length > 0 &&
      values.drugNameSynonyms.length === prev.drugNameSynonyms.length &&
      values.drugNameSynonyms[values.drugNameSynonyms.length - 1] !== '' &&
      values.drugNameSynonyms[values.drugNameSynonyms.length - 1] !== prev.drugNameSynonyms[values.drugNameSynonyms.length - 1]
    ) {
      dispatch(arrayPush(formName, 'drugNameSynonyms', null))
    }
  },
})(DrugEditWindow)

const mapStateToProps = (store: IAppState): DrugInjectedStateProps => {
  return {
    dispatch: store.dispatch,
    loading: store.drugs.edit.loading,
    errorMessage: store.drugs.edit.errorMessage
  }
}

const connectedForm = connect<DrugInjectedStateProps, unknown, DrugFormOwnProps, IAppState>(
  mapStateToProps,
  null
)(editReduxForm)

export default connectedForm
