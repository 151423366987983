import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import { IPharmaciesNetwork } from '../../../../proto/models'
import { connect } from 'react-redux'
import PharmaciesNetworkEditForm from '../PharmaciesNetworksEditForm'
import {
  fetchPharmaciesNetwork,
  onCreatePharmaciesNetwork,
  onUpdatePharmaciesNetwork,
  resetPhamaciesNetwork,
} from '../../../../reducers/pharmaciesNetworks/editPharmaciesNetworkReducer'
import styles from '../../pharmacies/PharmaciesEditWindow/PharmaciesEditWindow.module.scss'
import { navigate } from '@reach/router'
import { withPrefix } from 'gatsby'

interface IPharmaciesNetworkContext {
  dispatch: AppDispatch
  loading: boolean
  pharmacyNetwork: IPharmaciesNetwork
  id?: string
}

class PharmaciesNetworkEditWindow extends Component<IPharmaciesNetworkContext> {
  private loadData = (id: string) => {
    if (id) {
      this.props.dispatch(fetchPharmaciesNetwork(id))
    }
  }

  private onCreateBtnClick = () => {
    if (!this.props.id) {
      this.props.dispatch(onCreatePharmaciesNetwork())
    } else {
      this.props.dispatch(onUpdatePharmaciesNetwork(this.props.id))
    }
    this.onBackBtnClick()
  }

  private onBackBtnClick = () => {
    navigate(withPrefix('/pharmaciesNetworks'))
  }

  componentDidMount() {
    this.loadData(this.props.id)
  }

  componentWillUnmount() {
    this.props.dispatch(resetPhamaciesNetwork())
  }

  render() {
    if (this.props.loading) {
      return ''
    }

    return (
      <div>
        <h3>Аптечная сеть</h3>
        <PharmaciesNetworkEditForm />
        <div className={styles.buttonWrapper}>
          <Button variant="contained" color="primary" type="submit" onClick={this.onCreateBtnClick}>
            Сохранить
          </Button>
        </div>
        <div className={styles.buttonWrapper}>
          <Button variant="contained" color="primary" type="submit" onClick={this.onBackBtnClick}>
            Назад
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    loading: store.pharmaciesNetworks.edit.loading,
  }
}

export default connect(mapStateToProps, null)(PharmaciesNetworkEditWindow)
