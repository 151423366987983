import React from 'react'
import { List, ListSubheader, ListItem } from '@material-ui/core'
import PlaceIcon from '@material-ui/icons/Place'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy'
import style from './Navigation.module.scss'
import { navigate } from '@reach/router'
import { withPrefix } from 'gatsby'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup'
import LanguageIcon from '@material-ui/icons/Language'

const Navigation = (props) => {
  return (
    <List
      component="nav"
      className={style.nav_list}
      subheader={<ListSubheader component="div">Справочники</ListSubheader>}
    >
      <ListItem button onClick={(e) => navigate(withPrefix('/addresses'))}>
        <ListItemIcon>
          <PlaceIcon />
        </ListItemIcon>
        <ListItemText primary="Адреса" />
      </ListItem>

      <ListItem button onClick={(e) => navigate(withPrefix('/pharmacies'))}>
        <ListItemIcon>
          <LocalPharmacyIcon />
        </ListItemIcon>
        <ListItemText primary="Аптеки" />
      </ListItem>

      <ListItem button onClick={(e) => navigate(withPrefix('/pharmaciesNetworks'))}>
        <ListItemIcon>
          <LocalPharmacyIcon />
        </ListItemIcon>
        <ListItemText primary="Аптечные сети" />
      </ListItem>

      <ListItem button onClick={(e) => navigate(withPrefix('/drugs'))}>
        <ListItemIcon>
          <LocalPharmacyIcon />
        </ListItemIcon>
        <ListItemText primary="Препараты" />
      </ListItem>

      <ListItem button onClick={(e) => navigate(withPrefix('/currencies'))}>
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
        <ListItemText primary="Валюты" />
      </ListItem>

      <ListItem button onClick={(e) => navigate(withPrefix('/telephone-codes'))}>
        <ListItemIcon>
          <PhonelinkSetupIcon />
        </ListItemIcon>
        <ListItemText primary="Телефонные коды" />
      </ListItem>

      <ListItem button onClick={(e) => navigate(withPrefix('/languages'))}>
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary="Языки" />
      </ListItem>

      <ListItem button onClick={(e) => navigate(withPrefix('/categories'))}>
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary="Категории" />
      </ListItem>

      <ListItem button onClick={(e) => navigate(withPrefix('/nosologies'))}>
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary="Нозология" />
      </ListItem>
    </List>
  )
}

export default Navigation
