import { AuthService } from '../../../auth/services/authService'
import { AuthError } from '../exceptions/authError'
import { AuthHelper } from './authHelper'
import { ProtoHelper } from './protoHelper'

export class HttpHelper {
  private constructor() {}

  public static defaultOptions = { responseType: 'application/json', headers: {} }

  public static constructUrl = (apiPrefix: string, relativeUrl: string): string => `${apiPrefix}${relativeUrl}`

  public static request(url: string, method: string, options: any, params: any = null): Request {
    if (params) {
      var searchParams = new URLSearchParams(params).toString()
      url += '?' + searchParams
    }

    options = AuthHelper.setCsrfToHeader({ ...options, method })

    return new Request(url, options)
  }

  public static updateCsrf(request: Request) {
    if (typeof window !== 'undefined') {
      const csrfToken = AuthHelper.getCsrfToken()
      request.headers.set('X-Csrf-Token', csrfToken)
    }
  }

  public static getBefore(url: string, additionalOptions: any, params: any = {}): Request {
    return HttpHelper.request(url, 'GET', additionalOptions, params)
  }

  public static postBefore(url: string, data: any, requestProtoType: any, additionalOptions: any): Request {
    const dataRequestInit = HttpHelper.dataRequestInit(data, requestProtoType)
    additionalOptions = { ...additionalOptions, body: dataRequestInit.body }

    return HttpHelper.request(url, 'POST', additionalOptions)
  }

  public static putBefore(url: string, data: any, requestProtoType: any, additionalOptions: any): Request {
    const dataRequestInit = HttpHelper.dataRequestInit(data, requestProtoType)
    additionalOptions = { ...additionalOptions, body: dataRequestInit.body }

    return HttpHelper.request(url, 'PUT', additionalOptions)
  }

  public static deleteBefore(url: string, data: any, additionalOptions: any): Request {
    return HttpHelper.request(url, 'DELETE', additionalOptions, { id: data })
  }

  private static dataRequestInit(data: any, requestProtoType: any): any {
    return { body: ProtoHelper.requestBefore(data, requestProtoType) }
  }

  public static async sendRequest(request: Request): Promise<Response> {
    const requestClone = request.clone()

    let response = await fetch(request)

    if (AuthHelper.isNotAuthenticated(response)) {
      try {
        await AuthService.refreshToken()
      } catch (error) {
        throw new AuthError(encodeURIComponent(window.location.pathname), encodeURIComponent(window.location.href))
      }

      HttpHelper.updateCsrf(requestClone)

      response = await fetch(requestClone)
    }

    return response
  }
}
