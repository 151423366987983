import { Dispatch } from 'redux'
import { WebClient } from '@external/rp.ui/utils/protoClient'
import { UploaderService } from '../services/uploader.service'
import { SyntheticEvent } from 'react'

export interface IAddressesState {
  importing: boolean
  timer: NodeJS.Timeout
}

const initialState: IAddressesState = {
  importing: false,
  timer: null,
}

export const addressesUrl = 'dictionaries/addresses'

const uploaderService: UploaderService = new UploaderService()

const IMPORTING_ADDRESSES = 'IMPORTING_ADDRESSES'
const importingAddresses = () => (dispatch: Dispatch) => ({
  type: IMPORTING_ADDRESSES,
})

const ADDRESSES_IMPORTED = 'ADDRESSES_IMPORTED'
const addressesImported = () => ({
  type: ADDRESSES_IMPORTED,
})

export const importAddresses = (event: SyntheticEvent) => (dispatch: Dispatch) => {
  dispatch(importingAddresses())

  uploaderService.upload(event, addressesUrl + '/import')
}

export const sync = () => (dispatch: Dispatch) => {
  WebClient.request(addressesUrl + '/sync', 'POST')
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADDRESSES_IMPORTED:
      return { importing: false }
    case IMPORTING_ADDRESSES:
      return { importing: true }

    default:
      return state
  }
}
