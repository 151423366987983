import { IListState, IEditState } from '../../data/state'
import { IPharmaciesNetwork, PharmaciesNetworks, PagingAndSort, IPharmaciesNetworks } from '../../proto/models'
import { IAppState } from '../rootReducer'
import { Dispatch } from 'redux'
import { ProtoClient } from '@external/rp.ui/utils/protoClient'
import { fetchPharmacies } from '../pharmacy/listPharmacyReducer'

const pharmaciesNetworksUrl = 'dictionaries/pharmaciesNetworks'

const defaultPaging = {
  pageSize: 25,
  pageIndex: 0,
}

const defaultSorting = {
  sortColumn: 'Name',
  sortDirection: 'asc',
}

const initialState: IListState<IPharmaciesNetwork> = {
  loading: false,
  items: [],
  rowCount: 0,
  paging: { ...defaultPaging },
  sorting: { ...defaultSorting },
}

const LOADING_PHARMACIES_NETWORKS = 'LOADING_PHARMACIES_NETWORKS'
export const loadingPharmaciesNetworks = () => ({
  type: LOADING_PHARMACIES_NETWORKS,
})

const PHARMACIES_NETWORKS_LOADED = 'PHARMACIES_NETWORKS_LOADED'
export const pharmaciesNetworksLoaded = (networks: IPharmaciesNetworks) => ({
  type: PHARMACIES_NETWORKS_LOADED,
  payload: networks.items,
  rowCount: networks.rowCount,
})

const DELETING_PHARMACIES_NETWORKS = 'DELETING_PHARMACIES_NETWORKS'
export const deletingPharmaciesNetworks = () => ({
  type: DELETING_PHARMACIES_NETWORKS,
})

const PHARMACIES_NETWORKS_DELETED = 'PHARMACIES_NETWORKS_DELETED'
export const pharmaciesNetworksDeleted = (networks: IPharmaciesNetworks) => ({
  type: PHARMACIES_NETWORKS_DELETED,
  payload: networks.items,
  rowCount: networks.rowCount,
})

const CHANGE_PER_PAGE = 'CHANGE_PER_PAGE'
const changePageSize = (pageSize: number) => ({
  type: CHANGE_PER_PAGE,
  payload: pageSize,
})

const CHANGE_PAGE_INDEX = 'CHANGE_PAGE_INDEX'
const changePageIndex = (pageIndex: number) => ({
  type: CHANGE_PAGE_INDEX,
  payload: pageIndex,
})

export const onChangePageIndex = (pageIndex: number) => (dispatch: Dispatch) => {
  dispatch(changePageIndex(pageIndex))

  return dispatch(fetchPharmaciesNetworks())
}

export const onChangePageSize = (pageSize: number) => (dispatch: Dispatch) => {
  dispatch(changePageSize(pageSize))

  return dispatch(fetchPharmaciesNetworks())
}

export const fetchPharmaciesNetworks = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  dispatch(loadingPharmaciesNetworks())

  const data = getState().pharmaciesNetworks.list
  const paging = data.paging
  const sorting = data.sorting

  const pagingAndSort = PagingAndSort.create({ ...paging, ...sorting })

  const networks = await ProtoClient.get<IPharmaciesNetworks>(pharmaciesNetworksUrl, PharmaciesNetworks, pagingAndSort)

  return dispatch(pharmaciesNetworksLoaded(networks))
}

export const deletePharmaciesNetworks = (id: string) => async (dispatch: Dispatch, getState: () => IAppState) => {
  dispatch(deletingPharmaciesNetworks())
  await ProtoClient.delete(pharmaciesNetworksUrl, id)
  return dispatch(fetchPharmaciesNetworks())
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING_PHARMACIES_NETWORKS:
      return {
        ...state,
        loading: true,
        rowCount: 0,
        items: [],
        paging: { ...state.paging },
        sorting: { ...state.sorting },
      }
    case PHARMACIES_NETWORKS_LOADED:
      return {
        ...state,
        loading: false,
        rowCount: action.rowCount,
        items: action.payload,
        paging: { ...state.paging },
        sorting: { ...state.sorting },
      }
    case DELETING_PHARMACIES_NETWORKS:
      return {
        ...state,
        loading: true,
        rowCount: 0,
        items: [],
        paging: { ...state.paging },
        sorting: { ...state.sorting },
      }
    case PHARMACIES_NETWORKS_DELETED:
      return {
        ...state,
        loading: false,
        rowCount: action.rowCount,
        items: action.payload,
        paging: { ...state.paging },
        sorting: { ...state.sorting },
      }
    case CHANGE_PER_PAGE:
      return {
        ...state,
        paging: {
          ...state.paging,
          pageSize: action.payload,
          items: [],
          pageIndex: 0,
        },
      }
    case CHANGE_PAGE_INDEX:
      return {
        ...state,
        paging: { ...state.paging, pageIndex: action.payload, items: [] },
      }
    default:
      return state
  }
}
