import classNames from 'classnames'
import React from 'react'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'

import styles from './CustomInput.module.scss'

interface ICustomInputProps {
  input?: WrappedFieldInputProps
  meta?: WrappedFieldMetaProps
  placeholder?: string
  disabled?: boolean
  type: string
  value?: string
  required?: boolean
  label?: string
  isReserveLabelError?: boolean
  maxLength?: number
  size: 'large' | 'normal'
}

const CustomInput = (props: ICustomInputProps) => {
  const { isReserveLabelError, size } = props
  let disabled = props.disabled || false
  let meta: string

  if (props.meta && props.meta.touched && props.meta.error) {
    meta = props.meta.error
  }

  if (props.meta && props.meta.touched && props.meta.warning) {
    meta = props.meta.warning
  }

  const labelErrorClassName = classNames(styles.label, {
    [styles.labelError]: props.meta && props.meta.touched && (props.meta.error || props.meta.warning),
  })

  const inputClassName = classNames(styles.customInput, {
    [styles.customInputError]: props.meta && props.meta.touched && (props.meta.error || props.meta.warning),
  })

  return (
    <div className={styles.blockCustomInput}>
      {props.label && <label className={styles.label}>{props.label}</label>}
      <input
        maxLength={props.maxLength}
        value={props.value}
        {...props.input}
        placeholder={props.placeholder}
        className={classNames(inputClassName, {
          [styles.customInputLarge]: size === 'large',
          [styles.customInputNormal]: size === 'normal',
        })}
        disabled={disabled}
        required={props.required}
      />
      {isReserveLabelError && <span className={labelErrorClassName}>{meta}&nbsp;</span>}
    </div>
  )
}

export default CustomInput
